import { ApiResponse } from './api';

export enum UploadEntity {
  nationalId = 'nationalId',
  selfie = 'selfie',
  orderFile = 'orderFile',
  indexFund = 'indexFund',
  other = 'other',
  accountStatement = 'accountStatement',
  tradeConfirmation = 'tradeConfirmation',
}

export type FetchMediaFileResponse = ApiResponse<{ url: string }>;

export type UploadMediaFileRequest = { file: File; entity: UploadEntity };
export type UploadMediaFileResponse = ApiResponse<{ filename: string }>;
