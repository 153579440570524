import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { FakeButtonLink } from '../../../../components/FakeLink';
import { Table } from '../../../../components/Table';
import { fetchStatuses } from '../../../../constants/fetchStatuses';
import { fetchUserIndexFundsByUser } from '../../../../redux/modules/userIndexFund/userIndexFund.actions';
import { userIndexFundSelector } from '../../../../redux/modules/userIndexFund/userIndexFund.selectors';
import { locations } from '../../../../routes/locations';
import { IndexFund } from '../../../../types/indexFund';
import { SubscribedPortfolio } from '../../../../types/subscribedPortfolio';
import { UserIndexFund } from '../../../../types/userIndexFund';
import { round2Format, round4Format } from '../../../../utils/number';

interface OwnedSharesTableProps {
  userId: number;
  subscribedPortfolio: SubscribedPortfolio;
}

interface Item extends UserIndexFund {
  costBase: number;
}

// TODO: Filter user index funds by subscribed portfolio when support multiple portfolios in parallel

export function OwnedSharesTable({ userId, subscribedPortfolio }: OwnedSharesTableProps) {
  const columns = useRef<GridColDef[]>([
    {
      field: 'id',
      headerName: 'Id',
      aggregable: false,
    },
    {
      field: 'indexFund.nameEn',
      headerName: 'Index Fund',
      sortable: false,
      aggregable: false,
      flex: 1,
      valueGetter: (params) => {
        if (!params.row?.indexFund?.id) {
          return '';
        }
        return `(${params.row?.indexFund?.id}) ${params.row?.indexFund?.nameEn}`;
      },
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return;
        }

        return (
          <Tooltip title={params.value} followCursor>
            <FakeButtonLink startIcon={<OpenInNewIcon />} href={locations.indexFund(params.row?.indexFund?.id)}>
              {params.value}
            </FakeButtonLink>
          </Tooltip>
        );
      },
    },
    {
      field: 'numOfUnits',
      headerName: 'Num Of Units',
      width: 150,
      aggregable: false,
      renderCell: (params) => (params.value ? round4Format(params.value) : ''),
    },
    {
      field: 'costBase',
      headerName: 'Cost Base',
      width: 100,
      aggregable: false,
      sortable: false,
      filterable: false,
      renderCell: (params) => (params.value ? round2Format(params.value) : ''),
    },
    {
      field: 'totalCostBase',
      headerName: 'Total Cost Base',
      width: 180,
      type: 'number',
      sortable: false,
      filterable: false,
      valueGetter: (params) => (params.row?.costBase || 0) * (params?.row?.numOfUnits || 0),
      renderCell: (params) => round2Format(params.value),
    },
    {
      field: 'indexFund.netAssetValuePerUnit ',
      headerName: 'Nav',
      width: 100,
      aggregable: false,
      valueGetter: (params) => params.row?.indexFund?.netAssetValuePerUnit || 0,
      renderCell: (params) => (params.value ? round2Format(params.value) : ''),
    },
    {
      field: 'estimatedAmount',
      headerName: 'Total Market Value',
      sortable: false,
      filterable: false,
      width: 200,
      type: 'number',
      valueGetter: (params) => (params.row?.indexFund?.netAssetValuePerUnit || 0) * (params?.row?.numOfUnits || 0),
      renderCell: (params) => round2Format(params.value),
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      aggregable: false,
      sortable: false,
      type: 'date',
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      aggregable: false,
      sortable: false,
      type: 'date',
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
  ]);
  const [items, setItems] = useState<Item[]>([]);

  const { totalUserIndexFunds, userIndexFunds, fetchStatus } = useSelector(userIndexFundSelector);

  useEffect(() => {
    const newItems = subscribedPortfolio.subscribedPortfolioAssociatedIndexFund.map((spaif, index) => {
      const uif = userIndexFunds.find((item) => spaif.indexFundId === item.indexFund?.id) || {
        id: index * -1,
        userId: 0,
        numOfUnits: 0,
        indexFund: {
          id: 0,
          nameEn: '',
          netAssetValuePerUnit: 0,
        } as IndexFund,
        createdAt: new Date(),
        updatedAt: new Date(),
      };

      const costBase = spaif?.costBase || 0;

      return { ...uif, costBase };
    });

    setItems(newItems);
  }, [subscribedPortfolio, userIndexFunds]);

  return (
    <Table
      fetchItems={fetchUserIndexFundsByUser}
      fetchItemsCustomParams={{ userId }}
      rows={items}
      columns={columns.current}
      loading={fetchStatus === fetchStatuses.pending}
      rowCount={totalUserIndexFunds}
      disableRowGrouping
      density="compact"
      initialColumnVisibilityModel={{ id: false }}
      initialSortModel={[{ field: 'id', sort: 'desc' }]}
      initialAggregationModel={{ estimatedAmount: 'sum', totalCostBase: 'sum' }}
      toolbarExportProps={{
        csvOptions: { fileName: `owned-shares-user-${userId}` },
        excelOptions: { fileName: `owned-shares-user-${userId}` },
      }}
      wrapperHeight={400}
    />
  );
}
