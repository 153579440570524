import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useAppDispatch } from '../../redux/hooks';
import { fetchSatelliteAccounts } from '../../redux/modules/satelliteAccount/satelliteAccount.actions';
import { satelliteAccountSelector } from '../../redux/modules/satelliteAccount/satelliteAccount.selectors';
import { SatelliteAccountsDetails } from './SatelliteAccountsDetails';
import { SatelliteAccountUnitsTable } from './SatelliteAccountUnitsTable';

export function SatelliteTab() {
  const dispatch = useAppDispatch();
  const { error } = useSelector(satelliteAccountSelector);

  useEffect(() => {
    dispatch(fetchSatelliteAccounts());
  }, []);

  return (
    <>
      {error && <Alert severity="error">{error.message}</Alert>}
      <Grid item container flexDirection="column" component={Paper} sx={{ p: '24px', mt: 0 }} spacing={2}>
        <Typography variant="h6">Accounts</Typography>
        <SatelliteAccountsDetails />
      </Grid>
      {/* <Grid item container flexDirection="column" component={Paper} sx={{ p: '24px', mt: '24px' }} spacing={2}> */}
      {/*   <SatelliteAccountsTable /> */}
      {/* </Grid> */}
      <Grid item container flexDirection="column" component={Paper} sx={{ p: '24px', mt: '24px' }} spacing={2}>
        <Typography variant="h6">Units</Typography>
        <SatelliteAccountUnitsTable />
      </Grid>
    </>
  );
}
