import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { userFeeCalculationService } from '../../../api';
import { PaginatedRequest } from '../../../types/api';

export const fetchUserFeeCalculations = createAsyncThunk('user-fee-calculation/fetch-all', (data: PaginatedRequest) =>
  userFeeCalculationService.fetchUserFeeCalculations(data),
);

export const fetchUserFeeCalculation = createAsyncThunk('user-fee-calculation/fetch-by-id', (id: number) =>
  userFeeCalculationService.fetchUserFeeCalculation(id),
);

export const cleanUserFeeCalculations = createAction('user-fee-calculation/clean');
