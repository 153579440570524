import _capitalize from 'lodash/capitalize';
import _upperCase from 'lodash/upperCase';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { useSearchParamsState } from '../../hooks';
import { useAppDispatch } from '../../redux/hooks';
import { fetchAlpacaConfigs, updateAlpacaConfigs } from '../../redux/modules/alpacaConfig/alpacaConfig.actions';
import { alpacaConfigSelector } from '../../redux/modules/alpacaConfig/alpacaConfig.selectors';
import { fetchAppConfigs, updateAppConfigs } from '../../redux/modules/appConfig/appConfig.actions';
import { appConfigSelector } from '../../redux/modules/appConfig/appConfig.selectors';
import {
  fetchPortfolioConfigs,
  updatePortfolioConfigs,
} from '../../redux/modules/portfolioConfig/portfolioConfig.actions';
import { portfolioConfigSelector } from '../../redux/modules/portfolioConfig/portfolioConfig.selectors';
import { fetchUserConfigs, updateUserConfigs } from '../../redux/modules/userConfig/userConfig.actions';
import { userConfigSelector } from '../../redux/modules/userConfig/userConfig.selectors';
import { AlpacaConfigKey } from '../../types/alpacaConfig';
import { ConfigKey } from '../../types/appConfig';
import { PortfolioConfigKey } from '../../types/portfolioConfig';
import { UserConfigKey } from '../../types/userConfig';
import { SettingsForm } from './SettingsForm';
import { JadwaConfigKey } from '../../types/jadwaConfig';
import { jadwaConfigSelector } from '../../redux/modules/jadwaConfig/jadwaConfig.selectors';
import { fetchJadwaConfigs, updateJadwaConfigs } from '../../redux/modules/jadwaConfig/jadwaConfig.actions';
import { AnalyticsConfigKey } from '../../types/analyticsConfig';
import { analyticsConfigSelector } from '../../redux/modules/analyticsConfig/analyticsConfig.selectors';
import {
  fetchAnalyticsConfigs,
  updateAnalyticsConfigs,
} from '../../redux/modules/analyticsConfig/analyticsConfig.actions';

const mapTabParamToValue: { [prop: string]: string } = {
  app: '1',
  alpaca: '2',
  jadwa: '3',
  user: '4',
  portfolio: '5',
  analytics: '6',
  default: '1',
};
const mapTabValueToTabParam: { [prop: string]: string } = {
  1: 'app',
  2: 'alpaca',
  3: 'jadwa',
  4: 'user',
  5: 'portfolio',
  6: 'analytics',
};

const appConfigKeyToValueType: Record<ConfigKey, 'number' | 'text' | 'boolean'> = {
  [ConfigKey.CACHE_ALLOCATION_PERCENTAGE]: 'number',
  [ConfigKey.WEB_APP_VERSION]: 'text',
  [ConfigKey.UPLOAD_FLOW_ENABLED]: 'boolean',
};

const alpacaConfigKeyToValueType: Record<AlpacaConfigKey, 'number' | 'text' | 'boolean' | 'boolnumber'> = {
  [AlpacaConfigKey.PREFUNDING_ENABLED]: 'boolean',
  [AlpacaConfigKey.SUBSCRIPTION_USD_FX_RATE]: 'boolnumber',
  [AlpacaConfigKey.REDEMPTION_USD_FX_RATE]: 'boolnumber',
};

const jadwaConfigKeyToValueType: Record<JadwaConfigKey, 'number' | 'text' | 'boolean' | 'boolnumber'> = {
  [JadwaConfigKey.SUBSCRIPTION_USD_FX_RATE]: 'boolnumber',
  [JadwaConfigKey.REDEMPTION_USD_FX_RATE]: 'boolnumber',
};

const portfolioConfigKeyToValueType: Record<PortfolioConfigKey, 'number' | 'text' | 'boolean' | 'boolnumber'> = {
  [PortfolioConfigKey.CASH_ALLOCATION_PERCENTAGE]: 'number',
  [PortfolioConfigKey.NAV_USD_FX_RATE]: 'boolnumber',
  [PortfolioConfigKey.AUTO_INVESTMENT]: 'boolean',
  [PortfolioConfigKey.MINIMUM_PORTFOLIO_INVESTMENT]: 'number',
  [PortfolioConfigKey.MINIMUM_ADD_FUND_INVESTMENT]: 'number',
};

const userConfigKeyToValueType: Record<UserConfigKey, 'number' | 'text' | 'boolean'> = {
  [UserConfigKey.USER_AUTO_ACTIVATE]: 'boolean',
  [UserConfigKey.WITHDRAW_ENABLED]: 'boolean',
};

const analyticsConfigKeyToValueType: Record<AnalyticsConfigKey, 'number' | 'text'> = {
  [AnalyticsConfigKey.TOTAL_VISITORS]: 'number',
  [AnalyticsConfigKey.CONVERSION_STAGE]: 'text',
};

type SettingsKey = 'app' | 'alpaca' | 'jadwa' | 'user' | 'portfolio' | 'analytics';

const mapSettingsKeyToData: Record<SettingsKey, { ConfigKey: object; configKeyToValueType: Record<string, string> }> = {
  app: {
    // eslint-disable-next-line object-shorthand
    ConfigKey: ConfigKey,
    configKeyToValueType: appConfigKeyToValueType,
  },
  alpaca: {
    ConfigKey: AlpacaConfigKey,
    configKeyToValueType: alpacaConfigKeyToValueType,
  },
  jadwa: {
    ConfigKey: JadwaConfigKey,
    configKeyToValueType: jadwaConfigKeyToValueType,
  },
  user: {
    ConfigKey: UserConfigKey,
    configKeyToValueType: userConfigKeyToValueType,
  },
  portfolio: {
    ConfigKey: PortfolioConfigKey,
    configKeyToValueType: portfolioConfigKeyToValueType,
  },
  analytics: {
    ConfigKey: AnalyticsConfigKey,
    configKeyToValueType: analyticsConfigKeyToValueType,
  },
};

export function SettingsTab() {
  const dispatch = useAppDispatch();
  const [selectedTab, setSelectedTab] = useSearchParamsState('settingsTab', 'default');

  const { fetchStatus, updateStatus } = useSelector(appConfigSelector);

  const isLoading = fetchStatus === fetchStatuses.pending || updateStatus === fetchStatuses.pending;

  const { fetchStatus: appConfigFetchStatus, appConfigs, error: appConfigError } = useSelector(appConfigSelector);
  const { fetchStatus: userConfigFetchStatus, userConfigs, error: userConfigError } = useSelector(userConfigSelector);
  const {
    fetchStatus: portfolioConfigFetchStatus,
    portfolioConfigs,
    error: portfolioConfigError,
  } = useSelector(portfolioConfigSelector);
  const {
    fetchStatus: alpacaConfigFetchStatus,
    alpacaConfigs,
    error: alpacaConfigError,
  } = useSelector(alpacaConfigSelector);
  const {
    fetchStatus: jadwaConfigFetchStatus,
    jadwaConfigs,
    error: jadwaConfigError,
  } = useSelector(jadwaConfigSelector);
  const {
    fetchStatus: analyticsConfigFetchStatus,
    analyticsConfigs,
    error: analyticsConfigError,
  } = useSelector(analyticsConfigSelector);

  const getOptionLabel = (settingsKey: SettingsKey) => (option: string) => {
    const Enum = mapSettingsKeyToData[settingsKey].ConfigKey;

    const indexOfOption = Object.values(Enum).indexOf(option);

    return _capitalize(_upperCase(Object.keys(Enum)[indexOfOption] || option));
  };

  const getConfigKey = (settingsKey: SettingsKey) => (key: string, index: number) =>
    `${settingsKey}-config-key-${key}-${index}`;

  const getOptionType = (settingsKey: SettingsKey) => (option: string) => {
    // eslint-disable-next-line prefer-destructuring
    const configKeyToValueType = mapSettingsKeyToData[settingsKey].configKeyToValueType;

    return configKeyToValueType[option] || 'text';
  };

  const fetchConfigsApp = useCallback(() => {
    dispatch(fetchAppConfigs());
  }, []);

  const fetchConfigsAlpaca = useCallback(() => {
    dispatch(fetchAlpacaConfigs());
  }, []);

  const fetchConfigsJadwa = useCallback(() => {
    dispatch(fetchJadwaConfigs());
  }, []);

  const fetchConfigsUser = useCallback(() => {
    dispatch(fetchUserConfigs());
  }, []);

  const fetchConfigsPortfolio = useCallback(() => {
    dispatch(fetchPortfolioConfigs());
  }, []);

  const fetchConfigsAnalytics = useCallback(() => {
    dispatch(fetchAnalyticsConfigs());
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(mapTabValueToTabParam[newValue]);
  };

  return (
    <Container component="main" maxWidth="xl">
      <TabContext value={mapTabParamToValue[selectedTab]}>
        <Box>
          <TabList
            value={mapTabParamToValue[selectedTab]}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="Settings tab list"
          >
            <Tab
              iconPosition="start"
              label="App"
              value="1"
              sx={{ textTransform: 'capitalize' }}
              disableRipple
              disabled={isLoading}
            />
            <Tab
              iconPosition="start"
              label="Alpaca"
              value="2"
              sx={{ textTransform: 'capitalize' }}
              disableRipple
              disabled={isLoading}
            />
            <Tab
              iconPosition="start"
              label="Jadwa"
              value="3"
              sx={{ textTransform: 'capitalize' }}
              disableRipple
              disabled={isLoading}
            />
            <Tab
              iconPosition="start"
              label="User"
              value="4"
              sx={{ textTransform: 'capitalize' }}
              disableRipple
              disabled={isLoading}
            />
            <Tab
              iconPosition="start"
              label="Portfolio"
              value="5"
              sx={{ textTransform: 'capitalize' }}
              disableRipple
              disabled={isLoading}
            />
            <Tab
              iconPosition="start"
              label="Analytics"
              value="6"
              sx={{ textTransform: 'capitalize' }}
              disableRipple
              disabled={isLoading}
            />
          </TabList>
        </Box>
        <TabPanel value="1">
          <SettingsForm
            title="App configs"
            fetchConfigs={fetchConfigsApp}
            updateConfigs={updateAppConfigs}
            getOptionLabel={getOptionLabel('app')}
            getOptionType={getOptionType('app')}
            getConfigKey={getConfigKey('app')}
            fetchStatus={appConfigFetchStatus}
            options={Object.values(ConfigKey)}
            configs={appConfigs}
            error={appConfigError}
            configKeyToValueType={appConfigKeyToValueType}
          />
        </TabPanel>
        <TabPanel value="2">
          <SettingsForm
            title="Alpaca configs"
            fetchConfigs={fetchConfigsAlpaca}
            updateConfigs={updateAlpacaConfigs}
            getOptionLabel={getOptionLabel('alpaca')}
            getOptionType={getOptionType('alpaca')}
            getConfigKey={getConfigKey('alpaca')}
            fetchStatus={alpacaConfigFetchStatus}
            options={Object.values(AlpacaConfigKey)}
            configs={alpacaConfigs}
            error={alpacaConfigError}
            configKeyToValueType={alpacaConfigKeyToValueType}
          />
        </TabPanel>
        <TabPanel value="3">
          <SettingsForm
            title="Jadwa configs"
            fetchConfigs={fetchConfigsJadwa}
            updateConfigs={updateJadwaConfigs}
            getOptionLabel={getOptionLabel('jadwa')}
            getOptionType={getOptionType('jadwa')}
            getConfigKey={getConfigKey('jadwa')}
            fetchStatus={jadwaConfigFetchStatus}
            options={Object.values(JadwaConfigKey)}
            configs={jadwaConfigs}
            error={jadwaConfigError}
            configKeyToValueType={jadwaConfigKeyToValueType}
          />
        </TabPanel>
        <TabPanel value="4">
          <SettingsForm
            title="User configs"
            fetchConfigs={fetchConfigsUser}
            updateConfigs={updateUserConfigs}
            getOptionLabel={getOptionLabel('user')}
            getOptionType={getOptionType('user')}
            getConfigKey={getConfigKey('user')}
            fetchStatus={userConfigFetchStatus}
            options={Object.values(UserConfigKey)}
            configs={userConfigs}
            error={userConfigError}
            configKeyToValueType={userConfigKeyToValueType}
          />
        </TabPanel>
        <TabPanel value="5">
          <SettingsForm
            title="Portfolio configs"
            fetchConfigs={fetchConfigsPortfolio}
            updateConfigs={updatePortfolioConfigs}
            getOptionLabel={getOptionLabel('portfolio')}
            getOptionType={getOptionType('portfolio')}
            getConfigKey={getConfigKey('portfolio')}
            fetchStatus={portfolioConfigFetchStatus}
            options={Object.values(PortfolioConfigKey)}
            configs={portfolioConfigs}
            error={portfolioConfigError}
            configKeyToValueType={portfolioConfigKeyToValueType}
          />
        </TabPanel>
        <TabPanel value="6">
          <SettingsForm
            title="Analytics configs"
            fetchConfigs={fetchConfigsAnalytics}
            updateConfigs={updateAnalyticsConfigs}
            getOptionLabel={getOptionLabel('analytics')}
            getOptionType={getOptionType('analytics')}
            getConfigKey={getConfigKey('analytics')}
            fetchStatus={analyticsConfigFetchStatus}
            options={Object.values(AnalyticsConfigKey)}
            configs={analyticsConfigs}
            error={analyticsConfigError}
            configKeyToValueType={analyticsConfigKeyToValueType}
          />
        </TabPanel>
      </TabContext>
    </Container>
  );
}
