import React from 'react';
import { useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { useSearchParamsState } from '../../hooks';
import { appConfigSelector } from '../../redux/modules/appConfig/appConfig.selectors';
import { ActionsTab } from './ActionsTab';
import { SatelliteTab } from './SatelliteTab';
import { SettingsTab } from './SettingsTab';

const mapTabParamToValue: { [prop: string]: string } = {
  settings: '1',
  satellite: '2',
  actions: '3',
  default: '1',
};
const mapTabValueToTabParam: { [prop: string]: string } = {
  1: 'settings',
  2: 'satellite',
  3: 'actions',
};

export function Settings() {
  const [selectedTab, setSelectedTab] = useSearchParamsState('tab', 'default');

  const { fetchStatus, updateStatus } = useSelector(appConfigSelector);

  const isLoading = fetchStatus === fetchStatuses.pending || updateStatus === fetchStatuses.pending;

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(mapTabValueToTabParam[newValue]);
  };

  return (
    <Container component="main" maxWidth="xl">
      <TabContext value={mapTabParamToValue[selectedTab]}>
        <Box>
          <TabList
            value={mapTabParamToValue[selectedTab]}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="Settings tabs"
          >
            <Tab
              icon={<SettingsIcon />}
              iconPosition="start"
              label="Settings"
              value="1"
              sx={{ textTransform: 'capitalize' }}
              disableRipple
              disabled={isLoading}
            />
            <Tab
              icon={<AccountBalanceIcon />}
              iconPosition="start"
              label="Satellite"
              value="2"
              sx={{ textTransform: 'capitalize' }}
              disableRipple
              disabled={isLoading}
            />
            <Tab
              icon={<SettingsInputComponentIcon />}
              iconPosition="start"
              label="Actions"
              value="3"
              sx={{ textTransform: 'capitalize' }}
              disableRipple
              disabled={isLoading}
            />
          </TabList>
        </Box>
        <TabPanel value="1">
          <SettingsTab />
        </TabPanel>
        <TabPanel value="2">
          <SatelliteTab />
        </TabPanel>
        <TabPanel value="3">
          <ActionsTab />
        </TabPanel>
      </TabContext>
    </Container>
  );
}
