import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-premium';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { FakeButtonLink } from '../../components/FakeLink';
import { Table } from '../../components/Table';
import { TableRef } from '../../components/Table/Table';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { fundOrderStatusToInfo } from '../../constants/tables/fundOrder';
import { fetchFundOrdersByOrderId } from '../../redux/modules/portfolioOrder/portfolioOrder.actions';
import {
  portfolioOrderSelector,
  viewDetailsByOrderIdSelector,
} from '../../redux/modules/portfolioOrder/portfolioOrder.selectors';
import { locations } from '../../routes/locations';
import { FundOrder, FundOrderStatus, FundOrderType, SatelliteAccountRequestStatus } from '../../types/portfolioOrder';
import { UserPortfolioRequest } from '../../types/userPortfolioRequest';
import { round2Format, round4Format } from '../../utils/number';

interface FundOrdersTabProps {
  orderId: string;
  onSelectFundOrders: (fundOrders: FundOrder[]) => void;
}

export function FundOrdersTab({ orderId, onSelectFundOrders }: FundOrdersTabProps) {
  const tableRef = useRef<TableRef>(null);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const { satelliteAccountRequests, fetchSatelliteAccountRequestsStatus } = useSelector(viewDetailsByOrderIdSelector);
  const { totalFundOrders, fundOrders, fetchFundOrdersStatus } = useSelector(viewDetailsByOrderIdSelector);
  const { executeStatus, importExecutionFileStatus } = useSelector(portfolioOrderSelector);
  const { consolidateRequests } = useSelector(viewDetailsByOrderIdSelector);
  const loading =
    fetchFundOrdersStatus === fetchStatuses.pending ||
    importExecutionFileStatus === fetchStatuses.pending ||
    fetchSatelliteAccountRequestsStatus === fetchStatuses.pending ||
    executeStatus === fetchStatuses.pending;

  const getColumns = (): GridColDef[] => [
    {
      field: 'id',
      headerName: 'Id',
      width: 50,
      aggregable: false,
    },
    {
      field: 'type.name',
      headerName: 'type',
      type: 'singleSelect',
      valueOptions: Object.values(FundOrderType),
      width: 140,
      aggregable: false,
      valueGetter: (params) => params.row?.type?.name,
      groupingValueGetter: (params) => params.row?.type?.name,
    },
    {
      field: 'indexFund.nameEn',
      headerName: 'Fund',
      type: 'string',
      width: 100,
      aggregable: false,
      valueGetter: (params) => {
        if (!params.row?.indexFund?.id) {
          return '';
        }
        return `(${params.row?.indexFund?.id}) ${params.row?.indexFund?.nameEn}`;
      },
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return;
        }

        return (
          <Tooltip title={params.value} followCursor>
            <FakeButtonLink startIcon={<OpenInNewIcon />} href={locations.indexFund(params.row?.indexFund?.id)}>
              {params.value}
            </FakeButtonLink>
          </Tooltip>
        );
      },
    },
    {
      field: 'estimatedAmount',
      headerName: 'Invested Amount',
      type: 'number',
      width: 140,
      valueFormatter: (params) => round2Format(params.value),
    },
    {
      field: 'indexFund.nav',
      headerName: 'Nav',
      type: 'string',
      width: 70,
      aggregable: false,
      valueFormatter: (params) => (params.value ? round2Format(params.value) : ''),
      valueGetter: (params) => {
        if (!params.row.estimatedAmount) {
          return '';
        }

        return (params.row?.estimatedAmount || 0) / (params.row?.sharesCount || 1);
      },
    },
    {
      field: 'sharesCount',
      headerName: 'Units',
      type: 'number',
      width: 100,
      valueFormatter: (params) => round4Format(params.value),
    },
    {
      field: 'satelliteAmount',
      headerName: 'Satellite Contribution',
      type: 'number',
      width: 160,
      valueFormatter: (params) => round2Format(params.value),
      valueGetter: (params) => {
        const satelliteRequest = satelliteAccountRequests.find((item) => params.row?.id === item.fundOrder.id);

        let amount = satelliteRequest?.amount || 0;

        if ([FundOrderStatus.DONE, FundOrderStatus.FAILED].includes(params.row?.status?.name)) {
          amount = satelliteRequest?.actualAmount || 0;
        }

        if (satelliteRequest?.status?.name === SatelliteAccountRequestStatus.REJECTED) {
          amount = 0;
        }

        return amount;
      },
    },
    {
      field: 'satelliteUnits',
      headerName: 'Satellite Units',
      type: 'number',
      width: 100,
      valueFormatter: (params) => round4Format(params.value),
      valueGetter: (params) => {
        const satelliteRequest = satelliteAccountRequests.find((item) => params.row?.id === item.fundOrder.id);

        let units = satelliteRequest?.numOfUnits || 0;

        if ([FundOrderStatus.DONE, FundOrderStatus.FAILED].includes(params.row?.status?.name)) {
          units = satelliteRequest?.actualNumOfUnits || 0;
        }

        if (satelliteRequest?.status?.name === SatelliteAccountRequestStatus.REJECTED) {
          units = 0;
        }

        return units;
      },
    },
    {
      field: 'actualAmount',
      headerName: 'Actual Amount',
      type: 'number',
      width: 160,
      valueFormatter: (params) => round2Format(params.value),
    },
    {
      field: 'actualNav',
      headerName: 'Actual Nav',
      type: 'number',
      width: 100,
      aggregable: false,
      valueFormatter: (params) => (params.value ? round2Format(params.value) : ''),
      valueGetter: (params) => {
        if (!params.row.actualAmount) {
          return '';
        }

        const satelliteRequest = satelliteAccountRequests.find((item) => params.row?.id === item.fundOrder.id);

        let satelliteUnits = satelliteRequest?.actualNumOfUnits || 0;

        if (satelliteRequest?.status?.name !== SatelliteAccountRequestStatus.EXECUTED) {
          satelliteUnits = 0;
        }

        return params.row.actualAmount / (params.row.actualSharesCount + satelliteUnits || 1);
      },
    },
    {
      field: 'actualSharesCount',
      headerName: 'Actual Units',
      type: 'number',
      width: 100,
      valueFormatter: (params) => round4Format(params.value),
      valueGetter: (params) => {
        const satelliteRequest = satelliteAccountRequests.find((item) => params.row?.id === item.fundOrder.id);

        let satelliteUnits = satelliteRequest?.actualNumOfUnits || 0;

        if (satelliteRequest?.status?.name !== SatelliteAccountRequestStatus.EXECUTED) {
          satelliteUnits = 0;
        }

        return params.row.actualSharesCount + satelliteUnits;
      },
    },
    {
      field: 'status.name',
      headerName: 'Status',
      type: 'singleSelect',
      valueOptions: Object.values(FundOrderStatus),
      minWidth: 150,
      flex: 1,
      aggregable: false,
      valueGetter: (params) => params.row?.status?.name,
      renderCell: (params: GridRenderCellParams) => {
        const info = fundOrderStatusToInfo[params.row?.status?.name as FundOrderStatus] || {};
        const Icon = info?.icon;

        if (!Icon) {
          return params.row?.status?.name;
        }

        return <Chip variant="outlined" color={info?.color} icon={<Icon />} label={params.row?.status?.name} />;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      type: 'date',
      minWidth: 100,
      aggregable: false,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      type: 'date',
      minWidth: 100,
      aggregable: false,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
  ];

  const [columns, setColumns] = useState<GridColDef[]>(getColumns());

  useEffect(() => {
    if (importExecutionFileStatus === fetchStatuses.success || executeStatus === fetchStatuses.success) {
      tableRef.current?.reload();
    }
  }, [importExecutionFileStatus, executeStatus]);

  useEffect(() => {
    setSelectedIds([]);
  }, [fundOrders]);

  useEffect(() => {
    setColumns(getColumns());
  }, [satelliteAccountRequests]);

  useEffect(() => {
    const items = fundOrders.filter((item) => selectedIds.includes(item.id));

    onSelectFundOrders(items);
  }, [selectedIds]);

  const isRowSelectable = (params: GridRowParams<UserPortfolioRequest>): boolean =>
    !consolidateRequests.find((item) => item.fundOrder.id === params.row.id);

  return (
    <Table
      ref={tableRef}
      fetchItems={fetchFundOrdersByOrderId}
      rows={fundOrders}
      columns={columns}
      loading={loading}
      rowCount={totalFundOrders}
      fetchItemsCustomParams={{ orderId }}
      checkboxSelection
      rowSelectionModel={selectedIds}
      isRowSelectable={isRowSelectable}
      onSelectionModelChange={(sm) => setSelectedIds(sm as number[])}
      initialAggregationModel={{
        estimatedAmount: 'sum',
        satelliteAmount: 'sum',
        actualAmount: 'sum',
        sharesCount: 'sum',
        satelliteUnits: 'sum',
        actualSharesCount: 'sum',
      }}
      toolbarExportProps={{
        csvOptions: { fileName: `fund-orders-${orderId}` },
        excelOptions: { fileName: `fund-orders-${orderId}` },
      }}
    />
  );
}
