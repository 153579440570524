import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  GridRenderCellParams,
  GridColDef,
  GridRowParams,
  GridActionsCellItemProps,
  GridActionsCellItem,
} from '@mui/x-data-grid-premium';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import RedeemIcon from '@mui/icons-material/Redeem';
import { AsyncDialog } from '../../components/AsyncDialog';
import { AsyncDialogRef } from '../../components/AsyncDialog/AsyncDialog';
import { FakeButtonLink, GridActionsCellItemLink } from '../../components/FakeLink';
import { Table } from '../../components/Table';
import { TableRef } from '../../components/Table/Table';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { userPortfolioRequestStatusToInfo } from '../../constants/tables/userPortfolioRequest';
import { usePermissions } from '../../hooks';
import { useAppDispatch } from '../../redux/hooks';
import { fetchUserPortfolioRequestsByOrderId } from '../../redux/modules/portfolioOrder/portfolioOrder.actions';
import {
  portfolioOrderSelector,
  viewDetailsByOrderIdSelector,
} from '../../redux/modules/portfolioOrder/portfolioOrder.selectors';
import { withdraw } from '../../redux/modules/user/user.actions';
import { locations } from '../../routes/locations';
import {
  UserPortfolioRequest,
  UserPortfolioRequestStatus,
  UserPortfolioRequestType,
} from '../../types/userPortfolioRequest';
import { isDispatchApiSuccess } from '../../utils/api';
import { round2Format } from '../../utils/number';
import * as permissions from '../../utils/permissions';
import { WithdrawAmountDialog } from './WithdrawAmountDialog';

interface RequestsTabProps {
  orderId: string;
}

export function RequestsTab({ orderId }: RequestsTabProps) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const tableRef = useRef<TableRef>(null);
  const asyncDialogRef = useRef<AsyncDialogRef>(null);
  const [columns, setColumns] = useState<GridColDef[]>([
    {
      field: 'id',
      headerName: 'Id',
      width: 100,
      aggregable: false,
    },
    {
      field: 'type',
      headerName: 'type',
      type: 'singleSelect',
      valueOptions: Object.values(UserPortfolioRequestType),
      width: 180,
      aggregable: false,
      valueGetter: (params) => params.value?.name,
    },
    {
      field: 'userPortfolio.userId',
      headerName: 'User',
      width: 120,
      aggregable: false,
      valueGetter: (params) => params.row?.userPortfolio?.userId,
      groupingValueGetter: (params) => params.row?.userPortfolio?.userId,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return;
        }

        return (
          <FakeButtonLink startIcon={<OpenInNewIcon />} href={locations.user(params.row?.userPortfolio?.userId)}>
            {params.row?.userPortfolio?.userId}
          </FakeButtonLink>
        );
      },
    },
    {
      field: 'userPortfolio.cashAllocationValue',
      headerName: 'Cash',
      type: 'number',
      width: 120,
      valueGetter: (params) => params.row?.userPortfolio?.cacheAllocationValue,
      valueFormatter: (params) => round2Format(params.value),
    },
    {
      field: 'userPortfolio.fundAmount',
      headerName: 'Fund Amount',
      type: 'number',
      width: 120,
      valueFormatter: (params) => round2Format(params.value),
      valueGetter: (params) => {
        if (params.row?.type?.name === UserPortfolioRequestType.REDEMPTION) {
          return params.row?.changes?.redeemAll ? 0 : params.row?.changes?.amount;
        }

        return params.row?.userPortfolio?.fundAmount;
      },
      renderCell: (params: GridRenderCellParams) => {
        if (params.row?.type?.name === UserPortfolioRequestType.REDEMPTION && params.row?.changes?.redeemAll) {
          return 'all';
        }

        return params.formattedValue;
      },
    },
    {
      field: 'userPortfolio.subscribedPortfolio.nameEn',
      headerName: 'Portfolio',
      type: 'number',
      width: 250,
      aggregable: false,
      valueGetter: (params) => {
        if (params.row?.id) {
          return `(${params.row?.userPortfolio?.subscribedPortfolio?.id}) ${params.row?.userPortfolio?.subscribedPortfolio?.nameEn}`;
        }
        return '';
      },
      groupingValueGetter: (params) => params.row?.userPortfolio?.subscribedPortfolio?.nameEn,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return;
        }

        return (
          <Tooltip title={params.value} followCursor>
            <FakeButtonLink
              startIcon={<OpenInNewIcon />}
              href={locations.subscribedPortfolio(params.row?.userPortfolio?.subscribedPortfolio?.id)}
            >
              {params.value}
            </FakeButtonLink>
          </Tooltip>
        );
      },
    },
    {
      field: 'status.name',
      headerName: 'Status',
      type: 'singleSelect',
      valueOptions: Object.values(UserPortfolioRequestStatus),
      minWidth: 200,
      flex: 1,
      aggregable: false,
      valueGetter: (params) => params.row?.status?.name,
      renderCell: (params: GridRenderCellParams) => {
        const info = userPortfolioRequestStatusToInfo[params.row?.status?.name as UserPortfolioRequestStatus] || {};
        const Icon = info?.icon;

        if (!Icon) {
          return params.row?.status?.name;
        }

        return <Chip variant="outlined" color={info?.color} icon={<Icon />} label={params.row?.status?.name} />;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      type: 'date',
      minWidth: 100,
      aggregable: false,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      type: 'date',
      minWidth: 100,
      aggregable: false,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
  ]);
  const { totalUserPortfolioRequests, userPortfolioRequests, fetchUserPortfolioRequestsStatus } =
    useSelector(viewDetailsByOrderIdSelector);
  const { executeStatus, importExecutionFileStatus } = useSelector(portfolioOrderSelector);
  const loading =
    fetchUserPortfolioRequestsStatus === fetchStatuses.pending ||
    importExecutionFileStatus === fetchStatuses.pending ||
    executeStatus === fetchStatuses.pending;
  const canWithdraw = usePermissions({ items: [permissions.User.withdraw] });

  useEffect(() => {
    if (importExecutionFileStatus === fetchStatuses.success || executeStatus === fetchStatuses.success) {
      tableRef.current?.reload();
    }
  }, [importExecutionFileStatus, executeStatus]);

  const onWithdraw = async (userPortfolioRequest: UserPortfolioRequest) => {
    const amount = await asyncDialogRef.current?.show(userPortfolioRequest.changes.amount);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const res = await dispatch(withdraw({ id: userPortfolioRequest.userPortfolio!.userId, amount }));

    if (isDispatchApiSuccess(res)) {
      enqueueSnackbar('Withdraw successfully executed!', { variant: 'success' });
    } else {
      enqueueSnackbar('Withdraw execution failed!', { variant: 'error' });
    }
  };

  useEffect(() => {
    const actionColumn = {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      hideable: false,
      filterable: false,
      sortable: false,
      getActions: (params: GridRowParams) => {
        const actions: React.ReactElement<GridActionsCellItemProps>[] = [];

        if (params.row?.userPortfolio?.id) {
          actions.push(
            <GridActionsCellItemLink
              key={`${params.row.userPortfolio.id}-details-action`}
              icon={<OpenInNewIcon />}
              label="Details"
              href={locations.userPortfolioRequest(params.row.userPortfolio.id)}
            />,
          );
        }

        if (canWithdraw && params.row?.type?.name === UserPortfolioRequestType.REDEMPTION) {
          actions.push(
            <GridActionsCellItem
              key={`withdraw-${params.row.id}`}
              icon={<RedeemIcon />}
              label="Link"
              onClick={() => onWithdraw(params.row)}
            />,
          );
        }

        return actions;
      },
    };

    if (canWithdraw) {
      setColumns([actionColumn, ...columns]);
    }
  }, [canWithdraw]);

  return (
    <>
      <Table
        ref={tableRef}
        fetchItems={fetchUserPortfolioRequestsByOrderId}
        rows={userPortfolioRequests}
        columns={columns}
        loading={loading}
        rowCount={totalUserPortfolioRequests}
        pageSizeOptions={[50, 100, 300]}
        initialPageSize={50}
        fetchItemsCustomParams={{ orderId }}
        initialAggregationModel={{
          'userPortfolio.cashAllocationValue': 'sum',
          'userPortfolio.fundAmount': 'sum',
        }}
        toolbarExportProps={{
          csvOptions: { fileName: `user-portfolio-requests-${orderId}` },
          excelOptions: { fileName: `user-portfolio-requests-${orderId}` },
        }}
      />

      <AsyncDialog ref={asyncDialogRef} dialog={WithdrawAmountDialog} />
    </>
  );
}
