import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { UserIndexFund } from '../../../../types/userIndexFund';
import { SubscribedPortfolio } from '../../../../types/subscribedPortfolio';
import { IndexFund } from '../../../../types/indexFund';
import { PortfolioPositions } from '../../../../utils/PortfolioPositions';
import { StyledTableCell } from '../../../Permissions/PermissionsTable/PermissionsTableElements';
import COLORS from '../../../../constants/colors';
import { round2Format } from '../../../../utils/number';

export type PortfolioPositionSimpleRowDataItem = {
  indexFund: IndexFund;
  id: number;
  allocation: number;
  units: number;
  todayNav: number;
  costBase: number;
  costBaseValue: number;
  marketValue: number;
  totalSarReturn: number;
};

export type PortfolioPositionRowDataItem = PortfolioPositionSimpleRowDataItem & {
  totalReturn: number;
};

interface PortfolioPositionsTableProps {
  portfolio?: SubscribedPortfolio;
  userIndexFunds: UserIndexFund[];
}

const PortfolioPositionsTable = (props: PortfolioPositionsTableProps) => {
  const { portfolio, userIndexFunds } = props;
  const { t } = useTranslation();
  const [rows, setRows] = useState<PortfolioPositionRowDataItem[]>([]);

  useEffect(() => {
    if (!portfolio) {
      return;
    }

    const portfolioPositions = new PortfolioPositions({
      realTime: true,
      portfolio,
      userIndexFunds,
    });
    const newRows = portfolioPositions.calculatePositions();

    setRows(newRows);
  }, [portfolio, userIndexFunds]);

  return (
    <TableContainer component={Paper} elevation={1} sx={{ minWidth: { xs: 'unset', md: 650 } }}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{ minWidth: { xs: '150px', md: 'auto' } }}>Fund Strategy</StyledTableCell>
            <StyledTableCell>Allocation</StyledTableCell>
            <StyledTableCell sx={{ minWidth: { xs: '150px', md: 'auto' } }}>Cost Base Value</StyledTableCell>
            <StyledTableCell sx={{ minWidth: { xs: '150px', md: 'auto' } }}>Market Value</StyledTableCell>
            <StyledTableCell sx={{ minWidth: { xs: '150px', md: 'auto' } }}>Total Return</StyledTableCell>
            <StyledTableCell sx={{ minWidth: { xs: '150px', md: 'auto' } }}>Total SAR Return</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={`portfolio-positions-table-${row.id}`}>
              <StyledTableCell
                component="th"
                scope="row"
                sx={{
                  color: COLORS.PRIMARY_BLUE,
                  fontWeight: 600,
                  fontSize: { xs: '12px', md: '14px' },
                  lineHeight: '20px',
                  width: '15%',
                  minWidth: '150px',
                }}
              >
                {row.indexFund.strategy.nameEn}
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                sx={{
                  color: COLORS.MAIN_DARK,
                  fontWeight: 500,
                  fontSize: { xs: '12px', md: '14px' },
                  lineHeight: '20px',
                  width: '10%',
                  minWidth: '150px',
                }}
              >
                {round2Format(row.allocation)}%
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                sx={{
                  color: COLORS.MAIN_DARK,
                  fontWeight: 500,
                  fontSize: { xs: '12px', md: '14px' },
                  lineHeight: '20px',
                  width: '13%',
                  minWidth: '150px',
                }}
              >
                {round2Format(row.costBaseValue)}
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                sx={{
                  color: COLORS.MAIN_DARK,
                  fontWeight: 500,
                  fontSize: { xs: '12px', md: '14px' },
                  lineHeight: '20px',
                  width: '11%',
                  minWidth: '150px',
                }}
              >
                {round2Format(row.marketValue)}
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                sx={{
                  color: COLORS.MAIN_DARK,
                  fontWeight: 500,
                  fontSize: { xs: '12px', md: '14px' },
                  lineHeight: '20px',
                  width: '10%',
                  minWidth: '150px',
                }}
              >
                {round2Format(row.totalReturn)}%
              </StyledTableCell>
              <StyledTableCell
                component="th"
                scope="row"
                sx={{
                  color: COLORS.MAIN_DARK,
                  fontWeight: 500,
                  fontSize: { xs: '12px', md: '14px' },
                  lineHeight: '20px',
                  width: '13%',
                  minWidth: '150px',
                }}
              >
                {round2Format(row.totalSarReturn)}
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PortfolioPositionsTable;
