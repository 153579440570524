import { ApiResponse, PaginatedRequest } from '../api';
import { AlpacaAccount } from './account';

export enum AlpacaOrderStatus {
  NEW = 'new',
  ACCEPTED = 'accepted',
  HELD = 'held',
  PARTIALLY_FILLED = 'partially_filled',
  FILLED = 'filled',
  DONE_FOR_TODAY = 'done_for_day',
  CANCELED = 'canceled',
  EXPIRED = 'expired',
  REPLACED = 'replaced',
  PENDING_CANCEL = 'pending_cancel',
  PENDING_REPLACE = 'pending_replace',
}

export enum AlpacaOrderSide {
  BUY = 'buy',
  SELL = 'sell',
}

export enum AlpacaOrderStep {
  INITIATED = 'INITIATED',
  TRANSFER_CREATED = 'TRANSFER_CREATED',
  TRANSFER_COMPLETED = 'TRANSFER_COMPLETED',
  TRANSFER_RECEIVED = 'TRANSFER_RECEIVED',
  JOURNAL_CREATED = 'JOURNAL_CREATED',
  JOURNAL_COMPLETED = 'JOURNAL_COMPLETED',
  ORDER_CREATED = 'ORDER_CREATED',
  ORDER_COMPLETED = 'ORDER_COMPLETED',
  DONE = 'DONE',
}

enum JournalType {
  JNLC = 'JNLC',
  JNLS = 'JNLS',
}

enum JournalStatus {
  QUEUED = 'queued',
  SENT_TO_CLEARING = 'sent_to_clearing',
  PENDING = 'pending',
  EXECUTED = 'executed',
  REJECTED = 'rejected',
  CANCELED = 'canceled',
  REFUSED = 'refused',
  DELETED = 'deleted',
  CORRECT = 'correct',
}

export enum AlpacaTransferStatus {
  QUEUED = 'QUEUED', // Transfer is in queue to be processed
  APPROVAL_PENDING = 'APPROVAL_PENDING', // Transfer is pending approval
  PENDING = 'PENDING', // Transfer is pending processing
  SENT_TO_CLEARING = 'SENT_TO_CLEARING', // Transfer is being processed by the clearing firm
  REJECTED = 'REJECTED', // Transfer is rejected
  CANCELED = 'CANCELED', // Client initiated transfer cancellation
  APPROVED = 'APPROVED', // Transfer is approved
  COMPLETE = 'COMPLETE', // Transfer is completed
  RETURNED = 'RETURNED', // The bank issued an ACH return for the transfer
}

export enum AlpacaTransferDirection {
  INCOMING = 'INCOMING', // Funds incoming to user’s account (deposit)
  OUTGOING = 'OUTGOING', // Funds outgoing from user’s account (withdrawal)
}

enum AccountStatus {
  INACTIVE = 'INACTIVE', // Account not enabled to trade equities
  ONBOARDING = 'ONBOARDING', // The account has been created but we haven’t performed KYC yet. This is only used with Onfido.
  SUBMITTED = 'SUBMITTED', // Application has been submitted and in process of review
  ACTION_REQUIRED = 'ACTION_REQUIRED', // Application requires manual action
  EDITED = 'EDITED', // Application was edited (e.g. to match info from uploaded docs). This is a transient status.
  APPROVAL_PENDING = 'APPROVAL_PENDING', // Initial value. Application approval process is in process
  APPROVED = 'APPROVED', // Account application has been approved, waiting to be ACTIVE
  REJECTED = 'REJECTED', // Account application is rejected
  ACTIVE = 'ACTIVE', // Equities account is fully active and can start trading
  SUBMISSION_FAILED = 'SUBMISSION_FAILED', // Account submissions has failed
  DISABLED = 'DISABLED', // Account is disabled, comes after ACTIVE
  ACCOUNT_UPDATED = 'ACCOUNT_UPDATED', // Account has been updated
  ACCOUNT_CLOSED = 'ACCOUNT_CLOSED', // Account is closed
}

export type AlpacaEvent = {
  id: number;
  entityId: string;
  resource: string;
  uniqueKey: string;
  payload: IAnyObject;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type AlpacaUser = {
  id: number;
  userId: number;
  accountId: string;
  status: {
    id: number;
    name: AccountStatus;
  };
  createdAt: Date | string;
  updatedAt: Date | string;
};

export interface IAlpacaJournal {
  id: number;
  journalId: string;
  amount: number;
  actualAmount: number;
  fxRate: number;
  originalAmount: number;
  status: JournalStatus;
  type: JournalType;
  createdAt: Date | string;
  updatedAt: Date | string;
}

export interface IAlpacaTransfer {
  id: number;
  transferId: string;
  ntaId: string;
  fxRate: number;
  realFxRate: number;
  sarAmount: number;
  actualSarAmount: number;
  usdAmount: number;
  originalUsdAmount: number;
  actualUsdAmount: number;
  direction: AlpacaTransferDirection;
  status: AlpacaTransferStatus;
  anbTransferId: null;
  createdAt: Date | string;
  updatedAt: Date | string;
}

export interface IAlpacaOrder {
  id: number;
  fundOrderId: number;
  orderId: string;
  alpacaOrderId: string;
  alpacaActualAmount: number;
  alpacaActualUnits: number;
  alpacaActualPrice: number;
  side: AlpacaOrderSide;
  step: AlpacaOrderStep;
  error: string | object | null;
  tradeConfirmationReportCreated: false;
  accountStatementReportCreated: false;
  status: {
    id: number;
    name: AlpacaOrderStatus;
  };
  user: AlpacaUser;
  alpacaJournal?: IAlpacaJournal;
  alpacaTransfer?: IAlpacaTransfer;
  createdAt: Date | string;
  updatedAt: Date | string;
}

export type TradingAccount = {
  id: string;
  account_number: string;
  status: string;
  crypto_status: string;
  currency: string;
  buying_power: string;
  regt_buying_power: string;
  daytrading_buying_power: string;
  cash: string;
  cash_withdrawable: string;
  cash_transferable: string;
  accrued_fees: string;
  pending_transfer_out: string;
  portfolio_value: string;
  pattern_day_trader: boolean;
  trading_blocked: boolean;
  transfers_blocked: boolean;
  account_blocked: boolean;
  created_at: string;
  trade_suspended_by_user: boolean;
  multiplier: string;
  shorting_enabled: boolean;
  equity: string;
  last_equity: string;
  long_market_value: string;
  short_market_value: string;
  initial_margin: string;
  maintenance_margin: string;
  last_maintenance_margin: string;
  sma: string;
  daytrade_count: number;
  previous_close: string;
  last_long_market_value: string;
  last_short_market_value: string;
  last_cash: string;
  last_initial_margin: string;
  last_regt_buying_power: string;
  last_daytrading_buying_power: string;
  last_buying_power: string;
  last_daytrade_count: number;
  clearing_broker: string;
};

export type FetchAlpacaEventsRequest = PaginatedRequest & {
  payloadFilter: Record<string, string> | Record<string, string>[];
};
export type FetchAlpacaEventsResponse = ApiResponse<{ items: AlpacaEvent[]; totalItems: number }>;
export type FetchAlpacaOrdersResponse = ApiResponse<{ items: IAlpacaOrder[]; totalItems: number }>;
export type FetchAlpacaTransfersResponse = ApiResponse<{ items: IAlpacaTransfer[]; totalItems: number }>;
export type FetchAlpacaOrdersByMadkholOrderIdResponse = ApiResponse<IAlpacaOrder[]>;
export type FetchAlpacaAccountByUserIdResponse = ApiResponse<AlpacaAccount>;
export type FetchTradingAccountResponse = ApiResponse<TradingAccount>;

// TODO: Rename (delete Ex) after refactoring to RTK Query
export type FetchAlpacaOrdersExResponse = { items: IAlpacaOrder[]; totalItems: number };
