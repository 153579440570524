import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PreviewIcon from '@mui/icons-material/Preview';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-number-input';

import 'react-phone-number-input/style.css';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AsyncDialog } from '../../components/AsyncDialog';
import { AsyncDialogRef } from '../../components/AsyncDialog/AsyncDialog';
import { CustomTextField } from '../../components/CustomTextField';
import { StyledBadge } from '../../components/StyledBadge';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { userFormFields } from '../../forms/fields/formFields';
import { createUserFormSchema, editUserFormSchema } from '../../forms/validationSchema/formSchema';
import { usePermissions } from '../../hooks';
import { useAppDispatch } from '../../redux/hooks';
import {
  createUser,
  fetchKycByUserId,
  fetchUser,
  updateUser,
  updateUserStatus,
} from '../../redux/modules/user/user.actions';
import {
  userLoadingSelector,
  userSelector,
  userStatusOptionsLoadingSelector,
} from '../../redux/modules/user/user.selectors';
import { fetchUserCustodianData } from '../../redux/modules/userCustodianData/userCustodianData.actions';
import { userGroupSelector } from '../../redux/modules/userGroup/userGroup.selectors';
import { locations } from '../../routes/locations';
import { UserStatus } from '../../types/user';
import * as permissions from '../../utils/permissions';
import { StatusReasonDialog } from '../UserList/StatusReasonDialog';

interface UserTabProps {
  id: number;
}

export function UserTab({ id }: UserTabProps) {
  const { t } = useTranslation('apiError');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const asyncDialogRef = useRef<AsyncDialogRef>(null);
  const {
    editUser,
    userWorldCheckScreenData,
    userStatusOptions,
    updateStatusStatus,
    createStatus,
    updateStatus,
    error,
  } = useSelector(userSelector);
  const { userGroups, fetchStatus } = useSelector(userGroupSelector);
  const canUpdate = usePermissions({ items: [permissions.User.update] });
  const canUpdateStatus = usePermissions({ items: [permissions.User.statusUpdate] });
  const isLoading = useSelector(userLoadingSelector);
  const isLoadingGroupOptions = fetchStatus === fetchStatuses.pending;
  const isLoadingStatusOptions = useSelector(userStatusOptionsLoadingSelector);
  const isBlocked = editUser?.status?.name === UserStatus.BLOCKED;
  const isDisabled = editUser?.status?.name === UserStatus.INACTIVE;
  const isManualCheckRequired = editUser?.status?.name === UserStatus.MANUAL_CHECK_REQUIRED;
  const isActivated = editUser?.status?.name === UserStatus.ACTIVE;
  const isStatusPending = editUser?.status?.name === UserStatus.PENDING;
  const isStatusRegistered = editUser?.status?.name === UserStatus.REGISTERED;
  const { enqueueSnackbar } = useSnackbar();
  const isSaveLoading =
    isLoading || updateStatus === fetchStatuses.pending || updateStatusStatus === fetchStatuses.pending;
  const { handleSubmit, values, handleChange, setFieldValue, touched, errors, resetForm } = useFormik({
    initialValues: {
      [userFormFields.email]: '',
      [userFormFields.fullName]: '',
      [userFormFields.mobile]: '',
      [userFormFields.status]: 0,
      [userFormFields.group]: 1,
    },
    validationSchema: id ? editUserFormSchema : createUserFormSchema,
    onSubmit: (formValues) => {
      if (id) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { email, ...payload } = formValues;

        dispatch(updateUser({ id, ...payload }));
      } else {
        dispatch(createUser(formValues));
      }
    },
  });

  const onUpdateStatus = async (status: UserStatus) => {
    try {
      let disableReason;
      let approvalReason;

      if ([UserStatus.BLOCKED, UserStatus.INACTIVE].includes(status)) {
        disableReason = await asyncDialogRef?.current?.show();
      }

      if (status === UserStatus.ACTIVE && editUser?.status.name === UserStatus.MANUAL_CHECK_REQUIRED) {
        approvalReason = await asyncDialogRef?.current?.show();
      }

      dispatch(updateUserStatus({ id, status, disableReason, approvalReason }));
    } catch (e) {
      enqueueSnackbar(`The reason is required for status ${status}`, { variant: 'error' });
    }
  };

  useEffect(() => {
    if (updateStatus === fetchStatuses.success) {
      enqueueSnackbar('User updated!', { variant: 'success' });
      dispatch(fetchUser(id));
      dispatch(fetchKycByUserId(id));
      dispatch(fetchUserCustodianData(Number(id)));
    }
    if (createStatus === fetchStatuses.success) {
      enqueueSnackbar('User created!', { variant: 'success' });
      navigate(locations.userList());
    }
    if (updateStatusStatus === fetchStatuses.success) {
      enqueueSnackbar('Status updated!', { variant: 'success' });
      dispatch(fetchUser(id));
    }
  }, [updateStatus, createStatus, updateStatusStatus]);

  const onResetForm = () => {
    if (editUser) {
      resetForm({
        values: {
          [userFormFields.email]: editUser.email,
          [userFormFields.fullName]: editUser.fullName,
          [userFormFields.mobile]: editUser.mobile,
          [userFormFields.status]: editUser.status?.id,
          [userFormFields.group]: editUser.group?.id,
        },
      });
    } else {
      resetForm();
    }
  };

  useEffect(() => {
    if (!id) {
      const registeredStatus = userStatusOptions.find((uso) => uso.name === UserStatus.REGISTERED);

      setFieldValue(userFormFields.status, registeredStatus?.id);
    }
  }, [userStatusOptions]);

  useEffect(() => {
    if (editUser?.id && editUser?.id !== id) {
      navigate(locations.user(editUser?.id));
      return;
    }

    onResetForm();
  }, [editUser]);

  const getWorldCheckStatusTooltip = (data: IAnyObject | null) => {
    if (!data) {
      return 'No World check results';
    }

    return (
      <div>
        {data?.screenResult ? 'World Check Success' : 'World Check Warning'}
        <br />
        {new Date(data.updatedAt).toLocaleString('en-US')}
      </div>
    );
  };

  const getWorldCheckStatusColor = (data: IAnyObject | null): 'error' | 'success' | 'warning' => {
    if (!data) {
      return 'warning';
    }

    return data?.screenResult ? 'success' : 'error';
  };

  return (
    <>
      {error?.message && <Alert severity="error">{t(error.message)}</Alert>}
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Paper sx={{ width: '100%', height: '100%', padding: 2 }}>
              <Grid
                sx={{ width: '100%', height: '100%' }}
                container
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                {isLoading ? (
                  <Skeleton sx={{ padding: 1 }} variant="circular" width={124} height={124} />
                ) : (
                  <Box
                    sx={{
                      border: '1px dashed rgba(145, 158, 171, 0.32)',
                      borderRadius: '50%',
                      padding: 1,
                    }}
                  >
                    <StyledBadge
                      color={getWorldCheckStatusColor(userWorldCheckScreenData)}
                      overlap="circular"
                      badgeContent={
                        <Tooltip title={getWorldCheckStatusTooltip(userWorldCheckScreenData)} placement="right" open>
                          <pre>&nbsp;</pre>
                        </Tooltip>
                      }
                      variant="standard"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    >
                      <Avatar sx={{ width: 124, height: 124 }}>
                        {values[userFormFields.fullName]
                          .split(' ')
                          .slice(0, 2)
                          .map((i) => i.charAt(0))
                          .join('')}
                      </Avatar>
                    </StyledBadge>
                  </Box>
                )}
                {[UserStatus.INACTIVE, UserStatus.BLOCKED].includes(editUser?.status?.name as UserStatus) && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="caption" component="p" color="gray">
                      Disable Reason:
                    </Typography>
                    <Typography variant="subtitle2" component="p">
                      {editUser?.disableReason}
                    </Typography>
                  </Box>
                )}
                {UserStatus.ACTIVE === (editUser?.status?.name as UserStatus) && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="caption" component="p" color="gray">
                      Approval Reason:
                    </Typography>
                    <Typography variant="subtitle2" component="p">
                      {editUser?.approvalReason}
                    </Typography>
                  </Box>
                )}
                {Boolean(editUser?.errorMessage) && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="caption" component="p" color="gray">
                      Error Message:
                    </Typography>
                    <Typography variant="subtitle2" component="p">
                      {editUser?.errorMessage}
                    </Typography>
                  </Box>
                )}
                {isLoading && isLoadingStatusOptions ? (
                  <Skeleton variant="rectangular" width={142} height={38} sx={{ mt: 2 }} />
                ) : (
                  <FormControl size="small" sx={{ mt: 2 }} margin="normal" error={touched.status && !!errors.status}>
                    <InputLabel id="status">Status</InputLabel>
                    <Select
                      labelId="status"
                      id="status"
                      name="status"
                      value={values[userFormFields.status] || ''}
                      label="Status"
                      onChange={handleChange}
                      disabled
                    >
                      {userStatusOptions.map(({ id: usId, name }) => (
                        <MenuItem key={usId} value={usId}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {isLoading && isLoadingGroupOptions ? (
                  <Skeleton variant="rectangular" width={142} height={38} sx={{ mt: 2 }} />
                ) : (
                  <FormControl size="small" sx={{ mt: 2 }} margin="normal" error={touched.group && !!errors.group}>
                    <InputLabel id="group">Group</InputLabel>
                    <Select
                      labelId="group"
                      id="group"
                      name="group"
                      value={values[userFormFields.group] || ''}
                      label="Group"
                      onChange={handleChange}
                      disabled={isLoadingGroupOptions || !canUpdate}
                    >
                      {editUser?.group?.isDeleted && (
                        <MenuItem key={editUser.group.id} value={editUser.group.id} disabled>
                          {editUser.group.nameEn} (Deleted)
                        </MenuItem>
                      )}
                      {userGroups.map(({ id: usId, nameEn }) => (
                        <MenuItem key={usId} value={usId}>
                          {nameEn}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper sx={{ width: '100%', height: '100%', padding: 2 }}>
              <Grid container flexDirection="column" spacing={2} paddingX={2} paddingBottom={3}>
                <Grid item>
                  {isLoading ? (
                    <Skeleton variant="rectangular" width="100%" height={80} />
                  ) : (
                    <TextField
                      id="fullName"
                      fullWidth
                      name={userFormFields.fullName}
                      label="Full Name"
                      value={values[userFormFields.fullName]}
                      onChange={handleChange}
                      error={touched.fullName && !!errors.fullName}
                      helperText={touched.fullName && errors.fullName}
                      disabled={!canUpdate}
                      margin="normal"
                      required
                      autoFocus
                    />
                  )}
                </Grid>
                <Grid item>
                  {isLoading ? (
                    <Skeleton variant="rectangular" width="100%" height={80} />
                  ) : (
                    <TextField
                      id="email"
                      fullWidth
                      name={userFormFields.email}
                      label="User email"
                      value={values[userFormFields.email]}
                      onChange={handleChange}
                      error={touched.email && !!errors.email}
                      helperText={touched.email && errors.email}
                      margin="normal"
                      disabled={Boolean(Number(id)) || !canUpdate}
                      required
                      autoFocus
                    />
                  )}
                </Grid>
                <Grid item>
                  {isLoading ? (
                    <Skeleton variant="rectangular" width="100%" height={80} />
                  ) : (
                    <PhoneInput
                      placeholder="Enter mobile number"
                      value={values[userFormFields.mobile]}
                      name={userFormFields.mobile}
                      id="mobile"
                      label="Mobile"
                      type="mobile"
                      inputComponent={CustomTextField}
                      numberInputProps={{
                        margin: 'normal',
                        required: true,
                        fullWidth: true,
                        id: 'mobile',
                        name: userFormFields.mobile,
                        label: 'Mobile',
                        type: 'mobile',
                        error: touched.mobile && !!errors.mobile,
                        helperText: touched.mobile && errors.mobile,
                        autoComplete: 'mobile',
                      }}
                      disabled={!canUpdate}
                      onChange={(value: string) =>
                        handleChange({
                          target: {
                            name: userFormFields.mobile,
                            type: 'mobile',
                            value,
                          },
                        })
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid>
          <Box m={1} p={1} display="flex" justifyContent="space-between" alignItems="center">
            <Box hidden={!canUpdateStatus}>
              {id > 0 && !isStatusPending && isStatusRegistered && (
                <LoadingButton
                  id="ToPendingButton"
                  color="info"
                  variant="contained"
                  sx={{ mt: 3, mb: 2, mr: 2 }}
                  endIcon={<PreviewIcon />}
                  loading={isSaveLoading}
                  loadingPosition="end"
                  onClick={() => onUpdateStatus(UserStatus.PENDING)}
                >
                  To Pending
                </LoadingButton>
              )}
              {id > 0 && !isActivated && (
                <LoadingButton
                  id="ActivateButton"
                  color="success"
                  variant="contained"
                  sx={{ mt: 3, mb: 2, mr: 2 }}
                  endIcon={<CheckCircleOutlineIcon />}
                  loading={isSaveLoading}
                  loadingPosition="end"
                  onClick={() => onUpdateStatus(UserStatus.ACTIVE)}
                >
                  Activate
                </LoadingButton>
              )}
              {id > 0 && !isDisabled && isActivated && (
                <LoadingButton
                  id="DisableButton"
                  color="warning"
                  variant="contained"
                  sx={{ mt: 3, mb: 2, mr: 2 }}
                  endIcon={<PersonOffIcon />}
                  loading={isSaveLoading}
                  loadingPosition="end"
                  onClick={() => onUpdateStatus(UserStatus.INACTIVE)}
                >
                  Disable
                </LoadingButton>
              )}
              {id > 0 && !isBlocked && (isStatusPending || isActivated || isDisabled || isManualCheckRequired) && (
                <LoadingButton
                  id="BlockButton"
                  color="error"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  endIcon={<NoAccountsIcon />}
                  loading={isSaveLoading}
                  loadingPosition="end"
                  onClick={() => onUpdateStatus(UserStatus.BLOCKED)}
                >
                  Block
                </LoadingButton>
              )}
            </Box>
            <Box hidden={!canUpdate}>
              <LoadingButton
                id="SaveButton"
                type="submit"
                color="primary"
                variant="contained"
                sx={{ mt: 3, mb: 2, mr: 2 }}
                endIcon={<SaveIcon />}
                loading={isSaveLoading}
                loadingPosition="end"
              >
                {editUser ? 'Save' : 'Create'}
              </LoadingButton>
              <Button
                id="DiscardButton"
                sx={{ mt: 3, mb: 2 }}
                variant="contained"
                color="warning"
                startIcon={<RestartAltIcon />}
                onClick={onResetForm}
              >
                Discard
              </Button>
            </Box>
          </Box>
        </Grid>
      </form>
      <AsyncDialog ref={asyncDialogRef} dialog={StatusReasonDialog} />
    </>
  );
}
