export enum indexFundFormFields {
  bloombergTicker = 'bloombergTicker',
  code = 'code',
  nameEn = 'nameEn',
  nameAr = 'nameAr',
  secondNameEn = 'secondNameEn',
  secondNameAr = 'secondNameAr',
  asset = 'asset',
  source = 'source',
  strategy = 'strategy',
  manager = 'manager',
  objectiveEn = 'objectiveEn',
  objectiveAr = 'objectiveAr',
  geographicalFocus = 'geographicalFocus',
  shariaaComplaint = 'shariaaComplaint',
  allowFractions = 'allowFractions',
  expenseRatio = 'expenseRatio',
  valuationDay = 'valuationDay',
  valuationFrequency = 'valuationFrequency',
  type = 'type',
  category = 'category',
  currency = 'currency',
  status = 'status',
  netAssetValuePerUnit = 'netAssetValuePerUnit',
  originalNav = 'originalNav',
  fxRate = 'fxRate',
  assetUnderManagement = 'assetUnderManagement',
  inceptionDate = 'inceptionDate',
  managementFee = 'managementFee',
  minimumSubscription = 'minimumSubscription',
  minimumRedemption = 'minimumRedemption',
  subscriptionFee = 'subscriptionFee',
  redemptionFee = 'redemptionFee',
  otherFee = 'otherFee',
  benchmark = 'benchmark',
  topHoldings = 'topHoldings',
  topHoldingsAr = 'topHoldingsAr',
  numberHoldings = 'numberHoldings',
  riskLevel = 'riskLevel',
  y1mReturn = 'y1mReturn',
  y3mReturn = 'y3mReturn',
  y5mReturn = 'y5mReturn',
  y1mStdev = 'y1mStdev',
  y3mStdev = 'y3mStdev',
  y5mStdev = 'y5mStdev',
  y1mReturnRisk = 'y1mReturnRisk',
  y3mReturnRisk = 'y3mReturnRisk',
  y5mReturnRisk = 'y5mReturnRisk',
  attachments = 'attachments',
}

export enum indexFundBulkUpdateNavFormFields {
  items = 'items',
}
