import { ApiResponse } from './api';
import { Benchmark } from './benchmark';
import { FundManager } from './fundManager';
import { IndexFund } from './indexFund';

export enum PortfolioStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export interface PortfolioCurrencyOption {
  id: number;
  name: string;
}

export interface PortfolioStatusOption {
  id: number;
  name: PortfolioStatus;
}

export enum PortfolioFileType {
  FACT_SHEET = 'Fact Sheet',
  FACT_SHEET_AR = 'Fact Sheet Ar',
  DISCLOSURE = 'Disclosure',
  OTHER = 'Other',
}

export interface PortfolioFileTypeOption {
  id: number;
  name: PortfolioFileType;
}

export interface PortfolioUserCategoryOption {
  id: number;
  userCategory: {
    id: number;
    name: string;
  };
}

export interface PortfolioFile {
  id: number;
  portfolioFileType: PortfolioFileTypeOption;
  fileId: string;
  isPublic: boolean;
}

export interface PortfolioAssociatedIndexFund {
  id: number;
  indexFund: IndexFund;
  weight: number;
}

export type Portfolio = {
  id: number;
  nameEn: string;
  nameAr: string;
  descriptionEn: string;
  descriptionAr: string;
  objectiveEn: string;
  objectiveAr: string;
  shariaaComplaint: boolean;
  createdBy: number;
  expectedMinimumReturn: number;
  targetExpectedReturn: number;
  expectedMaximumReturn: number;
  targetExpectedRisk: number;
  benchmark: Benchmark;
  sharpRatio: number;
  userCategory: PortfolioUserCategoryOption[];
  status: PortfolioStatusOption;
  currency: PortfolioCurrencyOption;
  manager: FundManager;
  files: PortfolioFile[];
  associatedIndexFunds: PortfolioAssociatedIndexFund[];
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type FetchPortfolioResponse = ApiResponse<Portfolio>;
export type FetchPortfoliosResponse = ApiResponse<{
  items: Portfolio[];
  totalItems: number;
}>;
export type FetchPortfolioCurrencyOptionsResponse = ApiResponse<PortfolioCurrencyOption[]>;
export type FetchPortfolioStatusOptionsResponse = ApiResponse<PortfolioStatusOption[]>;
export type FetchPortfolioFileTypeOptionsResponse = ApiResponse<PortfolioFileTypeOption[]>;

export type UpdatePortfolioStatusRequest = Pick<Portfolio, 'id'> & { status: PortfolioStatus };
export type UpdatePortfolioStatusResponse = ApiResponse<{ message: string }>;

export type SavePortfolioRequest = Omit<
  Portfolio,
  | 'id'
  | 'status'
  | 'currency'
  | 'manager'
  | 'files'
  | 'associatedIndexFunds'
  | 'userCategory'
  | 'createdBy'
  | 'benchmark'
  | 'createdAt'
  | 'updatedAt'
> & {
  id?: number;
  files: { portfolioFileType: number; fileId: string; isPublic: boolean }[];
  associatedIndexFunds: { indexFund: number; weight: number }[];
  userCategory: number[];
  status: number;
  manager: number;
  currency: number;
  benchmark: number;
};
export type CreatePortfolioResponse = ApiResponse<Portfolio>;

export type UpdatePortfolioRequestFile = { id?: number; portfolioFileType: number; fileId: string; isPublic: boolean };
export type UpdatePortfolioRequestAssociatedIndexFund = { id?: number; indexFund: number; weight: number };
export type UpdatePortfolioRequest = Omit<
  Portfolio,
  | 'status'
  | 'createdBy'
  | 'currency'
  | 'manager'
  | 'files'
  | 'associatedIndexFunds'
  | 'userCategory'
  | 'benchmark'
  | 'createdAt'
  | 'updatedAt'
> & {
  files: UpdatePortfolioRequestFile[];
  associatedIndexFunds: UpdatePortfolioRequestAssociatedIndexFund[];
  userCategory: number[];
  status: number;
  currency: number;
  manager: number;
  benchmark: number;
};
export type UpdatePortfolioResponse = ApiResponse<Portfolio>;
