import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { DownwardIcon, NoopIcon, UpwardIcon } from '../../../../components/Icon';
import { fetchStatuses } from '../../../../constants/fetchStatuses';
import { fetchUserBankDetailsHistory } from '../../../../redux/modules/userBank/userBank.actions';
import { userBankSelector } from '../../../../redux/modules/userBank/userBank.selectors';
import { GridCellExpand, Table } from '../../../../components/Table';
import { round2Format } from '../../../../utils/number';

interface UserBankDetailsHistoryTableProps {
  userBankDetailsId: number;
}

export function UserBankDetailsHistoryTable({ userBankDetailsId }: UserBankDetailsHistoryTableProps) {
  const columns = useRef<GridColDef[]>([
    { field: 'id', headerName: 'Id', filterable: false },
    { field: 'userBankDetailsId', headerName: 'User Bank Details Id', type: 'number' },
    {
      field: 'prevAmount',
      headerName: 'Prev Amount',
      minWidth: 100,
      type: 'number',
      flex: 1,
      renderCell: (params) => round2Format(params.value),
    },
    {
      field: 'prevHold',
      headerName: 'Prev Hold',
      minWidth: 100,
      type: 'number',
      flex: 1,
      renderCell: (params) => round2Format(params.value),
    },
    {
      field: 'prevCashAllocationValue',
      headerName: 'Prev Cash Allocation Value',
      minWidth: 150,
      type: 'number',
      flex: 1,
      renderCell: (params) => round2Format(params.value),
    },
    {
      field: 'amount',
      headerName: 'Amount',
      minWidth: 100,
      type: 'number',
      flex: 1,
      renderCell: (params) => {
        let Icon = NoopIcon;

        if (params.value > 0) {
          Icon = UpwardIcon;
        }

        if (params.value < 0) {
          Icon = DownwardIcon;
        }

        return (
          <>
            <Icon />
            {round2Format(params.value)}
          </>
        );
      },
    },
    {
      field: 'hold',
      headerName: 'Hold',
      minWidth: 100,
      type: 'number',
      flex: 1,
      renderCell: (params) => {
        let Icon = NoopIcon;

        if (params.value > 0) {
          Icon = UpwardIcon;
        }

        if (params.value < 0) {
          Icon = DownwardIcon;
        }

        return (
          <>
            <Icon />
            {round2Format(params.value)}
          </>
        );
      },
    },
    {
      field: 'cashAllocationValue',
      headerName: 'Cash Allocation Value',
      minWidth: 150,
      type: 'number',
      flex: 1,
      renderCell: (params) => {
        let Icon = NoopIcon;

        if (params.value > 0) {
          Icon = UpwardIcon;
        }

        if (params.value < 0) {
          Icon = DownwardIcon;
        }

        return (
          <>
            <Icon />
            {round2Format(params.value)}
          </>
        );
      },
    },
    {
      field: 'newAmount',
      headerName: 'New Amount',
      minWidth: 100,
      type: 'number',
      flex: 1,
      renderCell: (params) => round2Format(params.value),
    },
    {
      field: 'newHold',
      headerName: 'New Hold',
      minWidth: 100,
      type: 'number',
      flex: 1,
      renderCell: (params) => round2Format(params.value),
    },
    {
      field: 'newCashAllocationValue',
      headerName: 'New Cash Allocation Value',
      minWidth: 150,
      type: 'number',
      flex: 1,
      renderCell: (params) => round2Format(params.value),
    },
    {
      field: 'metadata',
      headerName: 'Metadata',
      sortable: false,
      width: 200,
      renderCell: (params: GridRenderCellParams) => (
        <GridCellExpand value={JSON.stringify(params.value, null, 2) || ''} width={params.colDef.computedWidth} />
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      type: 'dateTime',
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
  ]);
  const { userBankDetailsHistory, totalUserBankDetailsHistory, fetchUserBankDetailsHistoryStatus } =
    useSelector(userBankSelector);

  return (
    <Table
      fetchItems={fetchUserBankDetailsHistory}
      rows={userBankDetailsHistory}
      columns={columns.current}
      loading={fetchUserBankDetailsHistoryStatus === fetchStatuses.pending}
      rowCount={totalUserBankDetailsHistory}
      disableRowGrouping
      density="compact"
      initialColumnVisibilityModel={{ id: false, userBankDetailsId: false, metadata: false }}
      initialSortModel={[{ field: 'id', sort: 'desc' }]}
      requiredFilters={[{ field: 'userBankDetailsId', value: `${userBankDetailsId}`, operator: 'is', id: 0 }]}
      toolbarExportProps={{
        csvOptions: { fileName: `user-bank-details-${userBankDetailsId}-history-${new Date().toLocaleString()}` },
        excelOptions: { fileName: `user-bank-details-${userBankDetailsId}-history-${new Date().toLocaleString()}` },
      }}
      wrapperHeight={300}
    />
  );
}
