import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { FakeButtonLink } from '../../../../components/FakeLink';
import { Table } from '../../../../components/Table';
import { TableRef } from '../../../../components/Table/Table';
import { fetchStatuses } from '../../../../constants/fetchStatuses';
import { fetchAggregatedStatistics } from '../../../../redux/modules/aggregated-statistic/aggregated-statistic.actions';
import { aggregatedStatisticSelector } from '../../../../redux/modules/aggregated-statistic/aggregated-statistic.selectors';
import { locations } from '../../../../routes/locations';

interface WalkTableProps {
  userId: number;
  openDialog: () => void;
}

type WalkItem = {
  id: string;
  userId: number;
  fundId: number | string;
  date: string;
  startPoint: number;
  fundAdditionalAmount: number;
  gainLoss: number;
  endMv: number;
};

// TODO: Filter user index funds by subscribed portfolio when support multiple portfolios in parallel

export function WalkTable({ userId, openDialog }: WalkTableProps) {
  const tableRef = useRef<TableRef>(null);
  const [columns] = useState<GridColDef[]>([
    {
      field: 'id',
      headerName: 'Id',
      filterable: false,
      groupable: false,
    },
    {
      field: 'userId',
      headerName: 'User Id',
      groupable: false,
      sortable: false,
    },
    {
      field: 'fundId',
      headerName: 'Fund Id',
      width: 100,
      filterable: false,
      groupable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        if (`${params.row.fundId}`.startsWith('cashAllocation_')) {
          return 'Cash Allocation Value';
        }

        if (!params.value) {
          return;
        }

        return (
          <Tooltip title={params.value} followCursor>
            <FakeButtonLink startIcon={<OpenInNewIcon />} href={locations.indexFund(params.value)}>
              {params.value}
            </FakeButtonLink>
          </Tooltip>
        );
      },
    },
    {
      field: 'date',
      headerName: 'Date',
      filterable: false,
      sortable: false,
      width: 100,
    },
    {
      field: 'startPoint',
      headerName: 'Start Point',
      type: 'number',
      minWidth: 100,
      flex: 1,
      filterable: false,
      groupable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        if (`${params.row.fundId}`.startsWith('cashAllocation_')) {
          return '';
        }

        return params.formattedValue;
      },
    },
    {
      field: 'fundAdditionalAmount',
      headerName: 'Fund Add. Amount',
      type: 'number',
      minWidth: 140,
      flex: 1,
      filterable: false,
      groupable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        if (`${params.row.fundId}`.startsWith('cashAllocation_')) {
          return '';
        }

        return params.formattedValue;
      },
    },
    {
      field: 'gainLoss',
      headerName: 'Gain / Loss',
      type: 'number',
      minWidth: 140,
      flex: 1,
      filterable: false,
      groupable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        if (`${params.row.fundId}`.startsWith('cashAllocation_')) {
          return '';
        }

        return params.formattedValue;
      },
    },
    {
      field: 'endMv',
      headerName: 'End MV',
      type: 'number',
      minWidth: 140,
      flex: 1,
      filterable: false,
      groupable: false,
      sortable: false,
    },
    {
      field: 'startUnits',
      headerName: 'Start Units',
      type: 'number',
      minWidth: 120,
      flex: 1,
      filterable: false,
      groupable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        if (`${params.row.fundId}`.startsWith('cashAllocation_')) {
          return '';
        }

        return params.formattedValue;
      },
    },
    {
      field: 'endUnits',
      headerName: 'End Units',
      type: 'number',
      minWidth: 120,
      flex: 1,
      filterable: false,
      groupable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        if (`${params.row.fundId}`.startsWith('cashAllocation_')) {
          return '';
        }

        return params.formattedValue;
      },
    },
    {
      field: 'startNav',
      headerName: 'Start NAV',
      type: 'number',
      minWidth: 100,
      flex: 1,
      filterable: false,
      groupable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        if (`${params.row.fundId}`.startsWith('cashAllocation_')) {
          return '';
        }

        return params.formattedValue;
      },
    },
    {
      field: 'endNav',
      headerName: 'End NAV',
      type: 'number',
      minWidth: 100,
      flex: 1,
      filterable: false,
      groupable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        if (`${params.row.fundId}`.startsWith('cashAllocation_')) {
          return '';
        }

        return params.formattedValue;
      },
    },
  ]);
  const [items, setItems] = useState<WalkItem[]>([]);
  const { aggregatedStatistics, totalAggregatedStatistics, fetchStatus } = useSelector(aggregatedStatisticSelector);
  const loading = fetchStatus === fetchStatuses.pending;

  useEffect(() => {
    const nextItems = aggregatedStatistics.reduce((res, ag) => {
      const tableItems = (ag?.indexFunds?.calculationTable || []).map((calculationItem) => ({
        id: `${ag.id}-${calculationItem.indexFundId}`,
        fundId: calculationItem.indexFundId,
        date: ag.date,
        userId: ag.userId,
        startPoint: calculationItem.mvStartOfDay,
        fundAdditionalAmount: calculationItem.fundAddition,
        gainLoss: calculationItem.sarReturn,
        endMv: calculationItem.mvEndOfDay,
        startUnits: calculationItem.unitsStartOfDay,
        endUnits: calculationItem.unitsEndOfDay,
        startNav: calculationItem.navStartOfDay,
        endNav: calculationItem.navEndOfDay,
      }));

      const totalEndMV = tableItems.reduce((acc, v) => acc + v.endMv, 0);

      res = res
        .concat(tableItems)
        // Add cash allocation
        .concat({
          id: `${ag.id}-${0}`,
          fundId: `cashAllocation_${ag.id}`,
          date: ag.date,
          userId: ag.userId,
          startPoint: 0,
          fundAdditionalAmount: 0,
          gainLoss: 0,
          endMv: ag.totalMarketValue - totalEndMV,
        });

      return res;
    }, [] as WalkItem[]);

    setItems(nextItems);
  }, [aggregatedStatistics]);

  return (
    <Table
      ref={tableRef}
      wrapperHeight={400}
      fetchItems={fetchAggregatedStatistics}
      rows={items}
      columns={columns}
      loading={loading}
      rowCount={totalAggregatedStatistics}
      density="compact"
      sortingMode="client"
      disableRowGrouping={false}
      rowGroupingColumnMode="single"
      initialRowGroupingModel={['date']}
      defaultGroupingExpansionDepth={1}
      initialFilterModel={{ items: [{ field: 'userId', value: userId, operator: 'is' }] }}
      requiredFilters={[{ field: 'userId', value: userId, operator: 'is' }]}
      initialSortModel={[{ field: 'id', sort: 'desc' }]}
      initialColumnVisibilityModel={{ id: false, userId: false, date: false }}
      initialAggregationModel={{
        startPoint: 'sum',
        fundAdditionalAmount: 'sum',
        gainLoss: 'sum',
        endMv: 'sum',
      }}
      getAggregationPosition={(groupNode) => (groupNode.depth === -1 ? null : 'footer')}
      toolbarExportProps={{
        csvOptions: { fileName: `walk-user-${userId}-${new Date().toLocaleDateString('en-US')}` },
        excelOptions: { fileName: `walk-user-${userId}-${new Date().toLocaleDateString('en-US')}` },
      }}
      customToolbarItems={[
        <Button
          id="RestoreStatisticsButton"
          key="restore-statistics-custom-toolbar-1"
          startIcon={<RefreshIcon />}
          onClick={openDialog}
        >
          Restore Statistics
        </Button>,
      ]}
    />
  );
}
