import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { fundStrategyService } from '../../../api';
import { PaginatedRequest } from '../../../types/api';
import { CreateFundStrategyRequest, UpdateFundStrategyRequest } from '../../../types/fundStrategy';

export const fetchFundStrategies = createAsyncThunk('fundStrategy/fetch-all', (data: PaginatedRequest) =>
  fundStrategyService.fetchFundStrategies(data),
);

export const fetchFundStrategiesAutocomplete = createAsyncThunk('fundStrategy/fetch-autocomplete', (term: string) =>
  fundStrategyService.fetchFundStrategiesAutocomplete(term),
);

export const fetchFundStrategy = createAsyncThunk('fundStrategy/get', (id: number) =>
  fundStrategyService.fetchFundStrategy(id),
);

export const deleteFundStrategy = createAsyncThunk('fundStrategy/delete', (id: number) =>
  fundStrategyService.deleteFundStrategy(id),
);

export const createFundStrategy = createAsyncThunk('fundStrategy/create', (fundStrategy: CreateFundStrategyRequest) =>
  fundStrategyService.createFundStrategy(fundStrategy),
);

export const updateFundStrategy = createAsyncThunk('fundStrategy/update', (fundStrategy: UpdateFundStrategyRequest) =>
  fundStrategyService.updateFundStrategy(fundStrategy),
);

export const resetEditFundStrategy = createAction('fundStrategy/reset-edit');

export const cleanFundStrategies = createAction('fundStrategy/clean');
