import { ApiResponse } from './api';

export enum FeeConfigStatus {
  CREATED = 'Created',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  DELETED = 'Deleted',
}

export enum PeriodicityTypes {
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
  ANNUALLY = 'Annually',
}

export interface FeeConfigStatusOption {
  id: number;
  name: FeeConfigStatus;
}

export type Periodicity = {
  id: number;
  name: PeriodicityTypes;
};

export type Holiday = {
  id: number;
  name: string;
  form: Date | string;
  to: Date | string;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type FeeConfig = {
  id: number;
  formula: string;
  percentage: number;
  minSubscription: number;
  vat: number;
  workDaysOnly: boolean;
  isDeleted: boolean;
  deletedAt: Date | string | null;
  createdBy: number;
  updatedBy: number | null;
  holidays: Holiday[];
  calculationPeriodicity: Periodicity;
  deductionPeriodicity: Periodicity;
  status: FeeConfigStatusOption;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type FeeConfigHistory = {
  id: number;
  prevFormula: string;
  prevPercentage: number;
  prevMinSubscription: number;
  prevVat: number;
  newFormula: string;
  newPercentage: number;
  newMinSubscription: number;
  newVat: number;
  metadata: IAnyObject;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type FetchFeeConfigResponse = ApiResponse<FeeConfig>;
export type FetchFeeConfigsResponse = ApiResponse<{
  items: FeeConfig[];
  totalItems: number;
}>;

export type FetchFeeConfigHistoryResponse = ApiResponse<{
  items: FeeConfigHistory[];
  totalItems: number;
}>;

// TODO: exclude holday after include it in form
export type CreateFeeConfigRequest = Omit<
  FeeConfig,
  | 'id'
  | 'holidays'
  | 'status'
  | 'calculationPeriodicity'
  | 'deductionPeriodicity'
  | 'isDeleted'
  | 'deletedAt'
  | 'createdBy'
  | 'updatedBy'
  | 'createdAt'
  | 'updatedAt'
> & {
  calculationPeriodicity: Periodicity | PeriodicityTypes;
  deductionPeriodicity: Periodicity | PeriodicityTypes;
};
export type CreateFeeConfigResponse = ApiResponse<FeeConfig>;

export type UpdateFeeConfigRequest = Omit<
  FeeConfig,
  'holidays' | 'status' | 'isDeleted' | 'deletedAt' | 'createdBy' | 'updatedBy' | 'createdAt' | 'updatedAt'
>;
export type UpdateFeeConfigResponse = ApiResponse<FeeConfig>;
