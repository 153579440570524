import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchStatuses } from '../../../../constants/fetchStatuses';
import { feeConfigSelector } from '../../../../redux/modules/feeConfig/feeConfig.selectors';
import { FeeConfig } from '../../../../types/feeConfig';
import { round2Format } from '../../../../utils/number';
import { FeeConfigHistoryTable } from './FeeConfigHistoryTable';

export function FeeConfigsInfo() {
  const { feeConfigs, fetchStatus } = useSelector(feeConfigSelector);
  const [selectedFeeConfig, setSelectedSelectedFeeConfig] = React.useState<string>('1');

  const isLoading = fetchStatus === fetchStatuses.pending;

  const handleChangeFeeConfigTab = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedSelectedFeeConfig(newValue);
  };

  useEffect(() => {
    if (feeConfigs.length && !selectedFeeConfig) {
      setSelectedSelectedFeeConfig(`${feeConfigs[0].id}`);
    }
  }, [feeConfigs]);

  const renderInfo = (feeConfig: FeeConfig) => (
    <Grid container>
      <Grid item container flexDirection="row" sx={{ mt: 0 }} spacing={2} xs={12}>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Current Formula</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{feeConfig?.formula || ''}</Typography>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Percentage</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{feeConfig?.percentage || 0}</Typography>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Minimum Subscription</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{round2Format(feeConfig?.minSubscription || 0)}</Typography>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">VAT</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{round2Format(feeConfig?.vat || 0)}</Typography>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Calculation Periodicity</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{feeConfig?.calculationPeriodicity.name || ''}</Typography>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Deduction Periodicity</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{feeConfig?.deductionPeriodicity.name || ''}</Typography>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Work Days Only</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{feeConfig?.workDaysOnly ? 'True' : 'False'}</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ pt: 4, pb: 2 }}>
        <Divider />
      </Grid>

      <Grid item container flexDirection="row" sx={{ mt: 0 }} spacing={2} xs={12}>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Created By</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{feeConfig?.createdBy || ''}</Typography>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Updated By</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{feeConfig?.updatedBy || ''}</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ pt: 4, pb: 2 }}>
        <Divider />
      </Grid>
      <Grid item container flexDirection="row" sx={{ mt: 0 }} spacing={2} xs={12}>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Last Update</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{new Date(feeConfig.updatedAt).toLocaleString('en-US')}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  if (isLoading) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <TabContext value={selectedFeeConfig}>
      <Box sx={{ width: '100%' }}>
        <TabList
          value={selectedFeeConfig}
          onChange={handleChangeFeeConfigTab}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="fee config"
        >
          {feeConfigs.map((fc) => (
            <Tab key={`fee-config-${fc.id}`} value={fc.id.toString()} label={`#${fc.id}`} />
          ))}
        </TabList>
      </Box>
      {feeConfigs.map((fc) => (
        <TabPanel key={`fee-config-panel-${fc.id}`} value={fc.id.toString()} sx={{ width: '100%' }}>
          {renderInfo(fc)}
        </TabPanel>
      ))}
      {selectedFeeConfig && (
        <Grid item container flexDirection="column" component={Paper} sx={{ p: '24px', mt: '24px' }} spacing={2}>
          <Typography variant="h6">History</Typography>
          <FeeConfigHistoryTable id={+selectedFeeConfig} />
        </Grid>
      )}
    </TabContext>
  );
}
