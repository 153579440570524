import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Tooltip from '@mui/material/Tooltip';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { FakeButtonLink } from '../../components/FakeLink';
import { Table } from '../../components/Table';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { mapUserIdToStatementActor } from '../../constants/payment';
import { fetchUserFeeCalculations } from '../../redux/modules/userFeeCalculation/userFeeCalculation.actions';
import { userFeeCalculationSelector } from '../../redux/modules/userFeeCalculation/userFeeCalculation.selectors';
import { locations } from '../../routes/locations';
import { UserFeeCalculationTypes } from '../../types/userFeeCalculation';

export function UserFeeCalculationsTab() {
  const columns = useRef<GridColDef[]>([
    { field: 'id', headerName: 'Id' },
    {
      field: 'userFee.userId',
      headerName: 'User Id',
      width: 100,
      valueGetter: (params) => params.row?.userFee?.userId,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return;
        }

        const staticStatementActor = mapUserIdToStatementActor[params.value];

        if (staticStatementActor) {
          return staticStatementActor;
        }

        return (
          <Tooltip title={params.value} followCursor>
            <FakeButtonLink startIcon={<OpenInNewIcon />} href={locations.user(params.value)}>
              {params.value}
            </FakeButtonLink>
          </Tooltip>
        );
      },
    },
    {
      field: 'totalFee',
      headerName: 'TotalFee',
      width: 110,
    },
    {
      field: 'managementFee',
      headerName: 'ManagementFee',
      width: 110,
    },
    {
      field: 'vat',
      headerName: 'VAT',
      width: 110,
    },
    {
      field: 'type.name',
      headerName: 'Type',
      type: 'singleSelect',
      valueOptions: Object.values(UserFeeCalculationTypes),
      width: 120,
      valueGetter: (params) => params.row?.type?.name,
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      sortable: false,
      type: 'dateTime',
      width: 170,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      type: 'dateTime',
      width: 170,
    },
  ]);
  const { totalUserFeeCalculations, UserFeeCalculations, fetchStatus } = useSelector(userFeeCalculationSelector);

  return (
    <Table
      fetchItems={fetchUserFeeCalculations}
      rows={UserFeeCalculations}
      columns={columns.current}
      loading={fetchStatus === fetchStatuses.pending}
      rowCount={totalUserFeeCalculations}
      initialColumnVisibilityModel={{ id: false, metadata: false }}
      initialSortModel={[{ field: 'updatedAt', sort: 'desc' }]}
      toolbarExportProps={{
        excelOptions: { fileName: `user-fees-calculation-${Date.now()}` },
        csvOptions: { fileName: `user-fees-calculation-${Date.now()}` },
      }}
    />
  );
}
