import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';
import { getSaveChartColor } from '../../utils/charts';

type Dataset = {
  label: string;
  data: number[];
  backgroundColor: string[];
  borderColor: string[];
  borderWidth: number;
};

interface AssetDoughnutChartProps {
  items: { label: string; value: number }[];
}

const AssetDoughnutChart = ({ items }: AssetDoughnutChartProps) => {
  const options: ChartOptions<'doughnut'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: ({ label, formattedValue }) => `${label}:${formattedValue}%`,
        },
      },
    },
  };
  const [data, setData] = useState({
    labels: [] as string[],
    datasets: [] as Dataset[],
  });

  useEffect(() => {
    const newData = { ...data, labels: [] as string[], datasets: { ...data.datasets } };
    newData.datasets = [
      {
        label: 'My First Dataset',
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
      },
    ];

    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      newData.labels.push(item.label);
      newData.datasets[0].data.push(item.value);
      newData.datasets[0].backgroundColor.push(getSaveChartColor(items.length, i));
      newData.datasets[0].borderColor.push(getSaveChartColor(items.length, i));
    }

    setData(newData);
  }, [items]);

  return <Doughnut data={data} options={options} />;
};

export default AssetDoughnutChart;
