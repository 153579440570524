import { ApiResponse } from './api';
import { UserFee } from './userFee';

export interface IUserFeeCalculationIndexFundItem extends IAnyObject {
  numOfUnits: number;
  indexFundId: number;
  netAssetValuePerUnit: number;
}

export interface IUserFeeCalculationIndexFunds {
  items: IUserFeeCalculationIndexFundItem[];
}

export enum UserFeeCalculationTypes {
  REGULAR_FEE = 'Regular Fee',
  FULLY_PAID_MONTHLY_FEE = 'Fully Paid Monthly Fee',
  FULL_REDEMPTION_FEE = 'Full Redemption Fee',
}

export interface UserFeeCalculationTypesOption {
  id: number;
  name: UserFeeCalculationTypes;
}

export type UserFeeCalculation = {
  id: number;
  totalFee: number;
  managementFee: number;
  vat: number;
  indexFunds: IUserFeeCalculationIndexFunds | null;
  date: Date | string;
  userFee: UserFee;
  type: UserFeeCalculationTypesOption;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type FetchUserFeeCalculationResponse = ApiResponse<UserFeeCalculation>;
export type FetchUserFeeCalculationsResponse = ApiResponse<{
  items: UserFeeCalculation[];
  totalItems: number;
}>;
