import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { feeConfigService } from '../../../api';
import { PaginatedRequest } from '../../../types/api';
import { CreateFeeConfigRequest, UpdateFeeConfigRequest } from '../../../types/feeConfig';

export const fetchFeeConfigs = createAsyncThunk('feeConfig/fetch-all', () => feeConfigService.fetchFeeConfigs());

export const fetchFeeConfigHistory = createAsyncThunk('feeConfig/fetch-config-history', (data: PaginatedRequest) =>
  feeConfigService.fetchFeeConfigHistory(data),
);

export const fetchFeeConfig = createAsyncThunk('feeConfig/get', (id: number) => feeConfigService.fetchFeeConfig(id));

export const deleteFeeConfig = createAsyncThunk('feeConfig/delete', (id: number) =>
  feeConfigService.deleteFeeConfig(id),
);

export const createFeeConfigItem = createAsyncThunk('feeConfig/create', (feeConfig: CreateFeeConfigRequest) =>
  feeConfigService.createFeeConfig(feeConfig),
);

export const updateFeeConfig = createAsyncThunk('feeConfig/update', (feeConfig: UpdateFeeConfigRequest) =>
  feeConfigService.updateFeeConfig(feeConfig),
);

export const resetEditFeeConfig = createAction('feeConfig/reset-edit');

export const cleanFeeConfigs = createAction('feeConfig/clean');
