import { IndexFund } from '../types/indexFund';
import { UpdatePortfolioRequestAssociatedIndexFund } from '../types/portfolio';

function getSelectedIndexFund(indexFunds: IndexFund[], indexFundId: number) {
  return indexFunds.find((i) => i.id === indexFundId);
}

export const getAssetChartItems = (
  indexFunds: IndexFund[],
  associatedIndexFunds: UpdatePortfolioRequestAssociatedIndexFund[],
  cashAllocationPercentage: number,
) => {
  const assetsWeight = associatedIndexFunds.reduce((res, item) => {
    const indexFund = getSelectedIndexFund(indexFunds, item.indexFund);

    if (!indexFund) {
      return res;
    }

    if (res[indexFund.asset.id]) {
      res[indexFund.asset.id].weight += +item.weight;
    } else {
      res[indexFund.asset.id] = { ...item };
    }

    return res;
  }, {} as Record<number, UpdatePortfolioRequestAssociatedIndexFund>);

  return [
    { label: 'Cash Allocation', value: cashAllocationPercentage },
    ...Object.values(assetsWeight).map((aif) => ({
      label: getSelectedIndexFund(indexFunds, aif.indexFund)?.asset?.nameEn || '',
      value: +aif.weight,
    })),
  ];
};
