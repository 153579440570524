import React, { useEffect } from 'react';
import Container from '@mui/material/Container';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import PaymentsIcon from '@mui/icons-material/Payments';
import InsightsIcon from '@mui/icons-material/Insights';
import SettingsIcon from '@mui/icons-material/Settings';
import { useSearchParamsState } from '../../hooks';
import { useAppDispatch } from '../../redux/hooks';
import { cleanPayments } from '../../redux/modules/payment/payment.actions';
import { UserFeeCalculationsTab } from './UserFeeCalculationsTab';
import { UserFeesTab } from './UserFeesTab';
import { UserFeeDeductionsTab } from './UserFeeDeductionsTab';
import { FeeConfigTab } from './FeeConfigTab';

enum TABS {
  CONFIG = 'config',
  USER_FEES = 'user_fees',
  DEDUCTION = 'deduction',
  CALCULATIONS = 'calculations',
}

export function Fee() {
  const dispatch = useAppDispatch();
  const [selectedTab, setSelectedTab] = useSearchParamsState('tab', TABS.CONFIG);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  useEffect(
    () => () => {
      dispatch(cleanPayments());
    },
    [],
  );

  const renderTabList = () => (
    <TabContext value={selectedTab}>
      <Box>
        <TabList
          value={selectedTab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="Payment tabs"
        >
          <Tab
            icon={<SettingsIcon />}
            iconPosition="start"
            label="Config"
            value={TABS.CONFIG}
            sx={{ textTransform: 'capitalize' }}
            disableRipple
          />
          <Tab
            icon={<InsightsIcon />}
            iconPosition="start"
            label="User Fees"
            value={TABS.USER_FEES}
            sx={{ textTransform: 'capitalize' }}
            disableRipple
          />
          <Tab
            icon={<PaymentsIcon />}
            iconPosition="start"
            label="Deductions"
            value={TABS.DEDUCTION}
            sx={{ textTransform: 'capitalize' }}
            disableRipple
          />
          <Tab
            icon={<PaymentsIcon />}
            iconPosition="start"
            label="Calculations"
            value={TABS.CALCULATIONS}
            sx={{ textTransform: 'capitalize' }}
            disableRipple
          />
        </TabList>
      </Box>
      <TabPanel value={TABS.CONFIG}>
        <FeeConfigTab />
      </TabPanel>
      <TabPanel value={TABS.USER_FEES}>
        <UserFeesTab />
      </TabPanel>
      <TabPanel value={TABS.DEDUCTION}>
        <UserFeeDeductionsTab />
      </TabPanel>
      <TabPanel value={TABS.CALCULATIONS}>
        <UserFeeCalculationsTab />
      </TabPanel>
    </TabContext>
  );

  return (
    <Container component="main" maxWidth="xl">
      {renderTabList()}
    </Container>
  );
}
