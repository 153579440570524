import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { indexFundService } from '../../../api';
import { PaginatedRequest } from '../../../types/api';
import {
  BulkUpdateIndexFundNavs,
  CreateIndexFundRequest,
  ImportFundChartDateFile,
  ImportFundNavDateFile,
  UpdateIndexFundRequest,
} from '../../../types/indexFund';

export const fetchIndexFunds = createAsyncThunk('indexFund/fetch-all', (data: PaginatedRequest) =>
  indexFundService.fetchIndexFunds(data),
);

export const fetchFundNavHistory = createAsyncThunk('indexFund/fetch-nav-history-items', (data: PaginatedRequest) =>
  indexFundService.fetchFundNavHistory(data),
);

export const fetchIndexFund = createAsyncThunk('indexFund/get', (id: number) => indexFundService.fetchIndexFund(id));

export const deleteIndexFund = createAsyncThunk('indexFund/delete', (id: number) =>
  indexFundService.deleteIndexFund(id),
);

export const createIndexFund = createAsyncThunk('indexFund/create', (indexFund: CreateIndexFundRequest) =>
  indexFundService.createIndexFund(indexFund),
);

export const updateIndexFund = createAsyncThunk('indexFund/update', (indexFund: UpdateIndexFundRequest) =>
  indexFundService.updateIndexFund(indexFund),
);

export const bulkUpdateIndexFundNavs = createAsyncThunk(
  'indexFund/bulk-update-navs',
  (items: BulkUpdateIndexFundNavs[]) => indexFundService.bulkUpdateIndexFundNavs(items),
);

export const fetchFundRiskLevelOptions = createAsyncThunk('indexFund/fetch-fund-risk-level-options', () =>
  indexFundService.fetchFundRiskLevelOptions(),
);

export const fetchFundCategoryOptions = createAsyncThunk('indexFund/fetch-fund-category-options', () =>
  indexFundService.fetchFundCategoryOptions(),
);

export const fetchFundStatusOptions = createAsyncThunk('indexFund/fetch-fund-status-options', () =>
  indexFundService.fetchFundStatusOptions(),
);

export const fetchFundTypeOptions = createAsyncThunk('indexFund/fetch-fund-type-options', () =>
  indexFundService.fetchFundTypeOptions(),
);

export const fetchFundSourceOptions = createAsyncThunk('indexFund/fetch-fund-source-options', () =>
  indexFundService.fetchFundSourceOptions(),
);

export const importNavDataFile = createAsyncThunk('indexFund/import-nav-data-file', (payload: ImportFundNavDateFile) =>
  indexFundService.importNavDataFile(payload),
);

export const importFundChartDataFile = createAsyncThunk(
  'indexFund/import-fund-chart-data-file',
  (payload: ImportFundChartDateFile) => indexFundService.importFundChartDataFile(payload),
);

export const getExampleNavDataFile = createAsyncThunk('indexFund/get-example-nav-data-file', () =>
  indexFundService.getExampleNavDataFile(),
);

export const getExampleFundChartDataFile = createAsyncThunk('indexFund/get-example-fund-chart-data-file', () =>
  indexFundService.getExampleFundChartDataFile(),
);

export const exportFundAndBenchmarkNavData = createAsyncThunk('indexFund/export-nav-data', (id: number) =>
  indexFundService.exportNavData(id),
);

export const fetchNavDataByFund = createAsyncThunk('indexFund/fetch-nav-data-by-fund', (id: number) =>
  indexFundService.fetchNavDataByFund(id),
);

export const resetEditIndexFund = createAction('indexFund/reset-edit');

export const cleanIndexFunds = createAction('indexFund/clean');
