class Locations {
  pathname = '/';

  constructor(pathname = '/') {
    this.pathname = pathname;
  }

  home = () => this.pathname;

  dashboard = () => `${this.pathname}dashboard`;

  settings = () => `${this.pathname}settings`;

  employeeList = () => `${this.pathname}employee`;

  employee = (id?: number) => `${this.pathname}employee/${id !== undefined ? id : ':id'}`;

  userList = () => `${this.pathname}user`;

  earlySignupUserList = () => `${this.pathname}early-signup-user`;

  user = (id?: number) => `${this.pathname}user/${id !== undefined ? id : ':id'}`;

  userGroupList = () => `${this.pathname}user-group`;

  portfolioList = () => `${this.pathname}portfolio`;

  portfolio = (id?: number) => `${this.pathname}portfolio/${id !== undefined ? id : ':id'}`;

  benchmarkList = () => `${this.pathname}benchmark`;

  benchmark = (id?: number) => `${this.pathname}benchmark/${id !== undefined ? id : ':id'}`;

  portfolioOrderList = () => `${this.pathname}portfolio-order`;

  orderExecutionList = () => `${this.pathname}order-execution`;

  orderExecution = (id?: string) => `${this.pathname}order-execution/${id !== undefined ? id : ':id'}`;

  portfolioOrder = (id?: string) => `${this.pathname}portfolio-order/${id !== undefined ? id : ':id'}`;

  subscribedPortfolioList = () => `${this.pathname}subscribed-portfolio`;

  subscribedPortfolio = (id?: number) => `${this.pathname}subscribed-portfolio/${id !== undefined ? id : ':id'}`;

  userPortfolioRequestList = () => `${this.pathname}user-portfolio-request`;

  userPortfolioRequest = (id?: number | string) =>
    `${this.pathname}user-portfolio-request/${id !== undefined ? id : ':id'}`;

  roleList = () => `${this.pathname}role`;

  role = (id?: number) => `${this.pathname}role/${id !== undefined ? id : ':id'}`;

  currencyList = () => `${this.pathname}currency`;

  currency = (id?: number) => `${this.pathname}currency/${id !== undefined ? id : ':id'}`;

  userCategoryList = () => `${this.pathname}user-category`;

  userCategory = (id?: number) => `${this.pathname}user-category/${id !== undefined ? id : ':id'}`;

  assetList = () => `${this.pathname}asset`;

  asset = (id?: number) => `${this.pathname}asset/${id !== undefined ? id : ':id'}`;

  indexFundList = () => `${this.pathname}index-fund`;

  indexFundNavList = () => `${this.pathname}index-fund-nav`;

  indexFund = (id?: number) => `${this.pathname}index-fund/${id !== undefined ? id : ':id'}`;

  fundGeographicalFocus = () => `${this.pathname}fund-geographical-focus`;

  fundManager = () => `${this.pathname}fund-manager`;

  fundStrategy = () => `${this.pathname}fund-strategy`;

  fundValuationDays = () => `${this.pathname}fund-valuation-days`;

  fundValuationFrequency = () => `${this.pathname}fund-valuation-frequency`;

  actionList = () => `${this.pathname}action`;

  resourceList = () => `${this.pathname}resource`;

  rolePermissionList = () => `${this.pathname}role-permission`;

  rolePermissionList2 = () => `${this.pathname}role-permission2`;

  report = () => `${this.pathname}report`;

  analytics = () => `${this.pathname}analytics`;

  payment = () => `${this.pathname}payment`;

  fee = () => `${this.pathname}fee`;

  login = () => `${this.pathname}login`;

  forgotPassword = () => `${this.pathname}forgot-password`;

  signUp = () => `${this.pathname}sign-up`;

  notFound = () => `${this.pathname}404`;
}

export const locations = new Locations();
