import Grid from '@mui/material/Grid';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import Alert from '@mui/material/Alert';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Switch from '@mui/material/Switch';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { fetchStatuses } from '../../../../constants/fetchStatuses';
import { feeConfigFormFields } from '../../../../forms/fields/formFields';
import { feeConfigFormSchema } from '../../../../forms/validationSchema/formSchema';
import { useAppDispatch } from '../../../../redux/hooks';
import { createFeeConfigItem, updateFeeConfig } from '../../../../redux/modules/feeConfig/feeConfig.actions';
import { feeConfigSelector } from '../../../../redux/modules/feeConfig/feeConfig.selectors';
import {
  CreateFeeConfigRequest,
  UpdateFeeConfigRequest,
  FeeConfig,
  PeriodicityTypes,
} from '../../../../types/feeConfig';

const mapStringToEnum: { [prop: string]: PeriodicityTypes } = {
  daily: PeriodicityTypes.DAILY,
  weekly: PeriodicityTypes.WEEKLY,
  monthly: PeriodicityTypes.MONTHLY,
  annually: PeriodicityTypes.ANNUALLY,
};

interface AddFeeConfigDialogProps {
  open: boolean;
  initialValue: FeeConfig | null;
  onClose: (isReload: boolean) => void;
}

export function AddFeeConfigDialog({ open, initialValue, onClose }: AddFeeConfigDialogProps) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, values, handleChange, touched, errors, submitForm, setValues, resetForm } = useFormik({
    initialValues: {
      [feeConfigFormFields.formula]: initialValue?.formula || '',
      [feeConfigFormFields.percentage]: initialValue?.percentage || 0,
      [feeConfigFormFields.minSubscription]: initialValue?.minSubscription || 0,
      [feeConfigFormFields.vat]: initialValue?.vat || 0,
      [feeConfigFormFields.workDaysOnly]: initialValue?.workDaysOnly || true,
      [feeConfigFormFields.calculationPeriodicity]: initialValue?.calculationPeriodicity || PeriodicityTypes.DAILY,
      [feeConfigFormFields.deductionPeriodicity]: initialValue?.deductionPeriodicity || PeriodicityTypes.MONTHLY,
    },
    validationSchema: feeConfigFormSchema,
    onSubmit: (formValues) => {
      const payload: CreateFeeConfigRequest = {
        formula: formValues.formula,
        percentage: formValues.percentage,
        minSubscription: formValues.minSubscription,
        vat: formValues.vat,
        workDaysOnly: formValues.workDaysOnly,
        calculationPeriodicity: mapStringToEnum[formValues.calculationPeriodicity as string],
        deductionPeriodicity: mapStringToEnum[formValues.deductionPeriodicity as string],
      };
      dispatch(createFeeConfigItem(payload));
    },
  });
  const { createStatus, updateStatus, error } = useSelector(feeConfigSelector);

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  useEffect(() => {
    setValues({
      formula: initialValue?.formula || '',
      percentage: initialValue?.percentage || 0,
      minSubscription: initialValue?.minSubscription || 0,
      vat: initialValue?.vat || 0,
      workDaysOnly: initialValue?.workDaysOnly || true,
      calculationPeriodicity: initialValue?.calculationPeriodicity || PeriodicityTypes.DAILY,
      deductionPeriodicity: initialValue?.deductionPeriodicity || PeriodicityTypes.MONTHLY,
    });
  }, [initialValue]);

  useEffect(() => {
    if (createStatus === fetchStatuses.success) {
      onClose(true);
      enqueueSnackbar('User Category created!', { variant: 'success' });
    }
    if (createStatus === fetchStatuses.rejected) {
      enqueueSnackbar('User Category creation error!', { variant: 'error' });
    }

    if (updateStatus === fetchStatuses.success) {
      onClose(true);
      enqueueSnackbar('User Category updated!', { variant: 'success' });
    }
  }, [createStatus, updateStatus]);

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>{initialValue?.id ? 'Edit Fee Config' : 'Create a new Fee Config'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {error && <Alert severity="error">{error.message}</Alert>}
          Enter the Formula
        </DialogContentText>
        <form onSubmit={handleSubmit}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="feeConfigFormual"
            name={feeConfigFormFields.formula}
            label="Fee Config Formula"
            type="feeConfigFormual"
            value={values[feeConfigFormFields.formula]}
            onChange={handleChange}
            error={touched.formula && !!errors.formula}
            helperText={touched.formula && errors.formula}
            inputProps={{ style: { textTransform: 'capitalize' } }}
            autoComplete="fee-config-formula"
          />
          <Grid container display="flex" spacing={2} justifyContent="space-around" alignItems="center">
            <Grid item xs={12} sm={5} sx={{ width: '100%' }}>
              <TextField
                margin="normal"
                fullWidth
                type="number"
                id={feeConfigFormFields.percentage}
                name={feeConfigFormFields.percentage}
                label="Percentage"
                value={values[feeConfigFormFields.percentage]}
                onChange={handleChange}
                error={touched.percentage && !!errors.percentage}
                helperText={touched.percentage && errors.percentage}
                autoComplete="fee-config-percentage"
              />
            </Grid>

            <Grid item xs={12} sm={5} sx={{ width: '100%' }}>
              <TextField
                margin="normal"
                fullWidth
                type="number"
                id={feeConfigFormFields.minSubscription}
                name={feeConfigFormFields.minSubscription}
                label="Min Subscription"
                value={values[feeConfigFormFields.minSubscription]}
                onChange={handleChange}
                error={touched.minSubscription && !!errors.minSubscription}
                helperText={touched.minSubscription && errors.minSubscription}
                autoComplete="fee-config-min-subscription"
              />
            </Grid>

            <Grid item xs={12} sm={5} sx={{ width: '100%' }}>
              <TextField
                margin="normal"
                fullWidth
                type="number"
                id={feeConfigFormFields.vat}
                name={feeConfigFormFields.vat}
                label="VAT"
                value={values[feeConfigFormFields.vat]}
                onChange={handleChange}
                error={touched.vat && !!errors.vat}
                helperText={touched.vat && errors.vat}
                autoComplete="fee-config-vat"
              />
            </Grid>
          </Grid>

          <FormControlLabel
            sx={{ mt: 2 }}
            label="Work Days Only"
            labelPlacement="start"
            componentsProps={{
              typography: { textTransform: 'capitalize' },
            }}
            name={feeConfigFormFields.workDaysOnly}
            id={feeConfigFormFields.workDaysOnly}
            control={
              <Switch
                name={feeConfigFormFields.workDaysOnly}
                checked={values[feeConfigFormFields.workDaysOnly]}
                onChange={handleChange}
              />
            }
          />

          <FormControl fullWidth margin="normal">
            <InputLabel id="calculation-periodicity-type-label">Calculation Periodicity Type</InputLabel>
            <Select
              labelId="calculation-periodicity-type-label"
              name={feeConfigFormFields.calculationPeriodicity}
              value={values[feeConfigFormFields.calculationPeriodicity]}
              label="Calculation Periodicity Type"
              onChange={handleChange}
              error={touched.calculationPeriodicity && !!errors.calculationPeriodicity}
            >
              <MenuItem value="daily">{PeriodicityTypes.DAILY}</MenuItem>
              <MenuItem value="weekly">{PeriodicityTypes.WEEKLY}</MenuItem>
              <MenuItem value="monthly">{PeriodicityTypes.MONTHLY}</MenuItem>
              <MenuItem value="annually">{PeriodicityTypes.ANNUALLY}</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel id="deduction-periodicity-type-label">Deduction Periodicity Type</InputLabel>
            <Select
              labelId="deduction-periodicity-type-label"
              name={feeConfigFormFields.deductionPeriodicity}
              value={values[feeConfigFormFields.deductionPeriodicity]}
              label="Deduction Periodicity Type"
              onChange={handleChange}
              error={touched.deductionPeriodicity && !!errors.deductionPeriodicity}
            >
              <MenuItem value="daily">{PeriodicityTypes.DAILY}</MenuItem>
              <MenuItem value="weekly">{PeriodicityTypes.WEEKLY}</MenuItem>
              <MenuItem value="monthly">{PeriodicityTypes.MONTHLY}</MenuItem>
              <MenuItem value="annually">{PeriodicityTypes.ANNUALLY}</MenuItem>
            </Select>
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          type="button"
          color="primary"
          variant="contained"
          endIcon={<SaveIcon />}
          loading={createStatus === fetchStatuses.pending}
          loadingPosition="end"
          onClick={submitForm}
        >
          {initialValue?.id ? 'Save' : 'Create'}
        </LoadingButton>
        <Button color="secondary" variant="contained" onClick={() => onClose(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
