import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import CancelIcon from '@mui/icons-material/Cancel';
import { UserFeeDeductionStatus } from '../../types/payment';

export const userFeeDeductionRequestStatusToInfo: {
  [prop in UserFeeDeductionStatus]: {
    color: 'error' | 'info' | 'success' | 'warning';
    icon: React.ElementType;
  };
} = {
  [UserFeeDeductionStatus.PROCESSING]: {
    color: 'info',
    icon: InfoIcon,
  },
  [UserFeeDeductionStatus.SUCCESS]: {
    color: 'success',
    icon: CheckCircleIcon,
  },
  [UserFeeDeductionStatus.FAILED]: {
    color: 'error',
    icon: CancelIcon,
  },
  [UserFeeDeductionStatus.PENDING]: {
    color: 'info',
    icon: InfoIcon,
  },
  [UserFeeDeductionStatus.APPROVED]: {
    color: 'success',
    icon: CheckCircleIcon,
  },
  [UserFeeDeductionStatus.REJECTED]: {
    color: 'error',
    icon: CancelScheduleSendIcon,
  },
};
