import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import {
  FetchFundStrategyResponse,
  FetchFundStrategiesAutocompleteResponse,
  FetchFundStrategiesResponse,
} from '../../../types/fundStrategy';
import {
  fetchFundStrategy,
  fetchFundStrategies,
  resetEditFundStrategy,
  cleanFundStrategies,
  updateFundStrategy,
  createFundStrategy,
  deleteFundStrategy,
  fetchFundStrategiesAutocomplete,
} from './fundStrategy.actions';
import { FundStrategyState } from './fundStrategy.types';

const initialState: FundStrategyState = {
  fundStrategies: [],
  fundStrategiesAutocomplete: [],
  totalFundStrategies: 0,
  editFundStrategy: null,
  fetchAutocompleteStatus: fetchStatuses.idle,
  fetchStatus: fetchStatuses.idle,
  deleteStatus: fetchStatuses.idle,
  updateStatus: fetchStatuses.idle,
  createStatus: fetchStatuses.idle,
  error: null,
};

const fundStrategiesSlice = createSlice({
  name: 'fundStrategies',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFundStrategies.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(fetchFundStrategies.fulfilled, (state, action: PayloadAction<FetchFundStrategiesResponse>) => {
      state.fundStrategies = action.payload.data?.items;
      state.totalFundStrategies = action.payload.data?.totalItems;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchFundStrategies.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchFundStrategiesAutocomplete.pending, (state) => {
      state.fetchAutocompleteStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(
      fetchFundStrategiesAutocomplete.fulfilled,
      (state, action: PayloadAction<FetchFundStrategiesAutocompleteResponse>) => {
        state.fundStrategiesAutocomplete = action.payload.data;
        state.fetchAutocompleteStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchFundStrategiesAutocomplete.rejected, (state, action) => {
      state.fetchAutocompleteStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchFundStrategy.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(fetchFundStrategy.fulfilled, (state, action: PayloadAction<FetchFundStrategyResponse>) => {
      state.editFundStrategy = action.payload.data;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchFundStrategy.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(updateFundStrategy.pending, (state) => {
      state.error = null;
      state.updateStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(updateFundStrategy.fulfilled, (state) => {
      state.updateStatus = fetchStatuses.success;
    });
    builder.addCase(updateFundStrategy.rejected, (state, action) => {
      state.updateStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(createFundStrategy.pending, (state) => {
      state.error = null;
      state.createStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
    });
    builder.addCase(createFundStrategy.fulfilled, (state, action: PayloadAction<FetchFundStrategyResponse>) => {
      state.editFundStrategy = action.payload.data;
      state.createStatus = fetchStatuses.success;
    });
    builder.addCase(createFundStrategy.rejected, (state, action) => {
      state.createStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(deleteFundStrategy.pending, (state) => {
      state.error = null;
      state.deleteStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(deleteFundStrategy.fulfilled, (state) => {
      state.editFundStrategy = null;
      state.deleteStatus = fetchStatuses.success;
    });
    builder.addCase(deleteFundStrategy.rejected, (state, action) => {
      state.deleteStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(resetEditFundStrategy.type, (state) => {
      state.editFundStrategy = null;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
      state.error = null;
    });

    builder.addCase(cleanFundStrategies.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const fundStrategyReducer = fundStrategiesSlice.reducer;
