import React from 'react';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import CallToActionIcon from '@mui/icons-material/CallToAction';
import CategoryIcon from '@mui/icons-material/Category';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import Groups3Icon from '@mui/icons-material/Groups3';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LogoutIcon from '@mui/icons-material/Logout';
import PaidIcon from '@mui/icons-material/Paid';
import PersonIcon from '@mui/icons-material/Person';
import PolicyIcon from '@mui/icons-material/Policy';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import SettingsIcon from '@mui/icons-material/Settings';
import SummarizeIcon from '@mui/icons-material/Summarize';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ViewListIcon from '@mui/icons-material/ViewList';
import PaymentsIcon from '@mui/icons-material/Payments';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import TopicIcon from '@mui/icons-material/Topic';
import SnippetFolderIcon from '@mui/icons-material/SnippetFolder';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import { locations } from '../../routes/locations';
import * as permissions from '../../utils/permissions';
import { MenuItems } from './Sidebar/types';

interface GetMenuItemsProps {
  handleLogout: () => void;
}

export const getMenuItems = ({ handleLogout }: GetMenuItemsProps): MenuItems => [
  [
    {
      title: 'Dashboard',
      // eslint-disable-next-line react/no-unstable-nested-components
      IconComponent: () => <DashboardIcon />,
      path: locations.dashboard(),
    },
    {
      title: 'Users',
      // eslint-disable-next-line react/no-unstable-nested-components
      IconComponent: () => <FolderSharedIcon />,
      permissionProps: { items: permissions.ReadUserManagement, checkType: 'some' },
      children: [
        {
          title: 'Users',
          permissionProps: { items: [permissions.User.list] },
          // eslint-disable-next-line react/no-unstable-nested-components
          IconComponent: () => <PersonIcon />,
          path: locations.userList(),
          pathRegexp: new RegExp(`^${locations.userList()}(/\\d+)?$`),
        },
        {
          title: 'Early Users',
          permissionProps: { items: [permissions.Anonymous.listEarlySignupUsers] },
          // eslint-disable-next-line react/no-unstable-nested-components
          IconComponent: () => <DirectionsRunIcon />,
          path: locations.earlySignupUserList(),
          pathRegexp: new RegExp(`^${locations.earlySignupUserList()}(/\\d+)?$`),
        },
        {
          title: 'User Groups',
          permissionProps: { items: [permissions.UserGroup.list] },
          // eslint-disable-next-line react/no-unstable-nested-components
          IconComponent: () => <Groups3Icon />,
          path: locations.userGroupList(),
        },
      ],
    },
    {
      title: 'Employees',
      permissionProps: { items: [permissions.Employee.list] },
      // eslint-disable-next-line react/no-unstable-nested-components
      IconComponent: () => <SupportAgentIcon />,
      path: locations.employeeList(),
      pathRegexp: new RegExp(`^${locations.employeeList()}(/\\d+)?$`),
    },
    {
      title: 'Portfolios',
      // eslint-disable-next-line react/no-unstable-nested-components
      IconComponent: () => <SnippetFolderIcon />,
      permissionProps: { items: permissions.ReadPortfolioManagement, checkType: 'some' },
      children: [
        {
          title: 'Currency',
          permissionProps: { items: [permissions.Currency.list] },
          // eslint-disable-next-line react/no-unstable-nested-components
          IconComponent: () => <PaidIcon />,
          path: locations.currencyList(),
        },
        {
          title: 'Asset',
          permissionProps: { items: [permissions.Asset.list] },
          // eslint-disable-next-line react/no-unstable-nested-components
          IconComponent: () => <AccountBalanceWalletIcon />,
          path: locations.assetList(),
        },
        {
          title: 'User Category',
          permissionProps: { items: [permissions.UserCategory.list] },
          // eslint-disable-next-line react/no-unstable-nested-components
          IconComponent: () => <AssignmentIndIcon />,
          path: locations.userCategoryList(),
        },
        {
          title: 'Portfolio',
          permissionProps: { items: [permissions.Portfolio.list] },
          // eslint-disable-next-line react/no-unstable-nested-components
          IconComponent: () => <ContactPageIcon />,
          path: locations.portfolioList(),
          pathRegexp: new RegExp(`^${locations.portfolioList()}(/\\d+)?$`),
        },
        {
          title: 'Benchmark',
          permissionProps: { items: [permissions.Benchmark.list] },
          // eslint-disable-next-line react/no-unstable-nested-components
          IconComponent: () => <ContactPageIcon />,
          path: locations.benchmarkList(),
          pathRegexp: new RegExp(`^${locations.benchmarkList()}(/\\d+)?$`),
        },
        {
          title: 'Subscribed',
          permissionProps: { items: [permissions.SubscribedPortfolio.list] },
          // eslint-disable-next-line react/no-unstable-nested-components
          IconComponent: () => <ContactPageIcon />,
          path: locations.subscribedPortfolioList(),
          pathRegexp: new RegExp(`^${locations.subscribedPortfolioList()}(/\\d+)?$`),
        },
      ],
    },
    {
      title: 'Order MGM',
      // eslint-disable-next-line react/no-unstable-nested-components
      IconComponent: () => <TopicIcon />,
      permissionProps: { items: permissions.ReadOrderManagement, checkType: 'some' },
      children: [
        {
          title: 'Requests',
          permissionProps: { items: [permissions.UserPortfolioRequest.list] },
          // eslint-disable-next-line react/no-unstable-nested-components
          IconComponent: () => <ViewListIcon />,
          path: locations.userPortfolioRequestList(),
          pathRegexp: new RegExp(`^${locations.userPortfolioRequestList()}(/\\d+)?$`),
        },
        {
          title: 'Orders',
          permissionProps: { items: [permissions.PortfolioOrder.list] },
          // eslint-disable-next-line react/no-unstable-nested-components
          IconComponent: () => <ListAltIcon />,
          path: locations.portfolioOrderList(),
          pathRegexp: new RegExp(`^${locations.portfolioOrderList()}(/[a-z0-9-]+)?$`),
        },
        {
          title: 'Executions',
          permissionProps: { items: [permissions.OrderExecution.list] },
          // eslint-disable-next-line react/no-unstable-nested-components
          IconComponent: () => <ReceiptIcon />,
          path: locations.orderExecutionList(),
          pathRegexp: new RegExp(`^${locations.orderExecutionList()}(/[a-z0-9-]+)?$`),
        },
      ],
    },
    {
      title: 'Fund MGM',
      // eslint-disable-next-line react/no-unstable-nested-components
      IconComponent: () => <FolderZipIcon />,
      permissionProps: { items: permissions.ReadFundManagement, checkType: 'some' },
      children: [
        {
          title: 'Index Funds',
          permissionProps: { items: [permissions.IndexFund.list] },
          // eslint-disable-next-line react/no-unstable-nested-components
          IconComponent: () => <TrendingUpIcon />,
          path: locations.indexFundList(),
          pathRegexp: new RegExp(`^${locations.indexFundList()}(/[a-z0-9-]+)?$`),
        },
        {
          title: 'Funds Navs',
          permissionProps: { items: [permissions.IndexFund.list, permissions.IndexFund.navBulkUpdate] },
          // eslint-disable-next-line react/no-unstable-nested-components
          IconComponent: () => <PriceChangeIcon />,
          path: locations.indexFundNavList(),
          pathRegexp: new RegExp(`^${locations.indexFundNavList()}(/[a-z0-9-]+)?$`),
        },
        {
          title: 'Geo. Focus',
          permissionProps: { items: [permissions.FundGeographicalFocus.list] },
          // eslint-disable-next-line react/no-unstable-nested-components
          IconComponent: () => <AccountBalanceWalletIcon />,
          path: locations.fundGeographicalFocus(),
        },
        {
          title: 'Manager',
          permissionProps: { items: [permissions.FundManager.list] },
          // eslint-disable-next-line react/no-unstable-nested-components
          IconComponent: () => <AssignmentIndIcon />,
          path: locations.fundManager(),
        },
        {
          title: 'Strategy',
          permissionProps: { items: [permissions.FundStrategy.list] },
          // eslint-disable-next-line react/no-unstable-nested-components
          IconComponent: () => <ContactPageIcon />,
          path: locations.fundStrategy(),
        },
        {
          title: 'Valuation Day',
          permissionProps: { items: [permissions.FundValuationDay.list] },
          // eslint-disable-next-line react/no-unstable-nested-components
          IconComponent: () => <ContactPageIcon />,
          path: locations.fundValuationDays(),
        },
        {
          title: 'Valuation Frequency',
          permissionProps: { items: [permissions.FundValuationFrequency.list] },
          // eslint-disable-next-line react/no-unstable-nested-components
          IconComponent: () => <ViewListIcon />,
          path: locations.fundValuationFrequency(),
        },
      ],
    },
    {
      title: 'Permission',
      // eslint-disable-next-line react/no-unstable-nested-components
      IconComponent: () => <PolicyIcon />,
      permissionProps: { items: permissions.ReadPermissionManagement, checkType: 'some' },
      children: [
        {
          title: 'Roles',
          permissionProps: { items: [permissions.Role.list] },
          // eslint-disable-next-line react/no-unstable-nested-components
          IconComponent: () => <PolicyIcon />,
          path: locations.roleList(),
        },
        {
          title: 'Resources',
          permissionProps: { items: [permissions.Resource.list] },
          // eslint-disable-next-line react/no-unstable-nested-components
          IconComponent: () => <CategoryIcon />,
          path: locations.resourceList(),
        },
        {
          title: 'Actions',
          permissionProps: { items: [permissions.Action.list] },
          // eslint-disable-next-line react/no-unstable-nested-components
          IconComponent: () => <CallToActionIcon />,
          path: locations.actionList(),
        },
        {
          title: 'Permissions',
          permissionProps: { items: [permissions.RolePermission.list] },
          // eslint-disable-next-line react/no-unstable-nested-components
          IconComponent: () => <AdminPanelSettingsIcon />,
          path: locations.rolePermissionList(),
        },
        {
          title: 'Permissions2',
          permissionProps: { items: [permissions.RolePermission.list] },
          // eslint-disable-next-line react/no-unstable-nested-components
          IconComponent: () => <AdminPanelSettingsIcon />,
          path: locations.rolePermissionList2(),
        },
      ],
    },
    {
      title: 'Payment',
      // eslint-disable-next-line react/no-unstable-nested-components
      IconComponent: () => <PaymentsIcon />,
      permissionProps: {
        items: [permissions.PaymentPermission.paymentStatement, permissions.PaymentPermission.paymentApiLogs],
        checkType: 'some',
      },
      path: locations.payment(),
    },
    {
      title: 'Fee',
      // eslint-disable-next-line react/no-unstable-nested-components
      IconComponent: () => <ReceiptLongIcon />,
      permissionProps: {
        items: [permissions.PaymentPermission.paymentStatement, permissions.PaymentPermission.paymentApiLogs],
        checkType: 'some',
      },
      path: locations.fee(),
    },
    {
      title: 'Report',
      // eslint-disable-next-line react/no-unstable-nested-components
      IconComponent: () => <SummarizeIcon />,
      permissionProps: { items: [permissions.ReportPermission.list], checkType: 'some' },
      path: locations.report(),
    },
    {
      title: 'Analytics',
      // eslint-disable-next-line react/no-unstable-nested-components
      IconComponent: () => <AnalyticsIcon />,
      // TODO: add analytics api to admin-serivce to use permission functionality
      // permissionProps: { items: [permissions.AnalyticsPermission.list], checkType: 'some' },
      path: locations.analytics(),
    },
    {
      title: 'Settings',
      // eslint-disable-next-line react/no-unstable-nested-components
      IconComponent: () => <SettingsIcon />,
      permissionProps: { items: permissions.Settings, checkType: 'some' },
      path: locations.settings(),
    },
  ],
  [
    {
      title: 'Logout',
      // eslint-disable-next-line react/no-unstable-nested-components
      IconComponent: () => <LogoutIcon />,
      onClick: () => handleLogout(),
    },
  ],
];
