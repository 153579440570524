import DeleteIcon from '@mui/icons-material/Delete';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { ClipboardEvent, useEffect } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomTextField } from '../../components/CustomTextField';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { employeeFormFields } from '../../forms/fields/formFields';
import { createEmployeeFormSchema, editEmployeeFormSchema } from '../../forms/validationSchema/formSchema';
import { parseApiError } from '../../helpers/api';
import { useFetchRolesQuery } from '../../redux/features/role/role.apiSlice';
import { useAppDispatch } from '../../redux/hooks';
import { authSelector } from '../../redux/features/auth/auth.selectors';
import {
  createEmployee,
  deleteEmployee,
  fetchEmployee,
  resetEditEmployee,
  updateEmployee,
} from '../../redux/modules/employee/employee.actions';
import { employeeSelector } from '../../redux/modules/employee/employee.selectors';
import { locations } from '../../routes/locations';
import { EmployeeStatus } from '../../types/employee';

export function Employee() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    fetchStatus,
    editEmployee,
    deleteStatus,
    createStatus,
    updateStatus,
    error: employeeError,
  } = useSelector(employeeSelector);
  const { enqueueSnackbar } = useSnackbar();
  const { user: authUser } = useSelector(authSelector);

  const { data: roles, isFetching: isRolesFetching, error: roleError } = useFetchRolesQuery({ limit: 100, offset: 0 });

  const error = employeeError || roleError;

  const isLoading = fetchStatus === fetchStatuses.pending || isRolesFetching;
  const isDeleteLoading = isLoading || deleteStatus === fetchStatuses.pending;
  const isSaveLoading = isLoading || createStatus === fetchStatuses.pending || updateStatus === fetchStatuses.pending;

  const { handleSubmit, values, handleChange, touched, errors, resetForm } = useFormik({
    initialValues: {
      [employeeFormFields.email]: '',
      [employeeFormFields.name]: '',
      [employeeFormFields.phone]: '',
      [employeeFormFields.role]: roles?.items?.[0]?.id || 0,
      [employeeFormFields.status]: EmployeeStatus.ACTIVE,
      ...(Number(id)
        ? {}
        : {
            [employeeFormFields.password]: '',
            [employeeFormFields.passwordConfirmation]: '',
          }),
    },
    validationSchema: Number(id) ? editEmployeeFormSchema : createEmployeeFormSchema,
    onSubmit: (formValues) => {
      const payload = { ...formValues, name: formValues.name.trim() };
      delete payload.passwordConfirmation;

      if (Number(id)) {
        delete payload.password;

        dispatch(updateEmployee({ id: Number(id), ...payload }));
      } else {
        dispatch(createEmployee(payload));
      }
    },
  });

  useEffect(
    () => () => {
      dispatch(resetEditEmployee());
    },
    [],
  );

  useEffect(() => {
    if (deleteStatus === fetchStatuses.success) {
      enqueueSnackbar('Employee deleted!', { variant: 'success' });
      navigate(locations.employeeList());
    }
    if (createStatus === fetchStatuses.success) {
      enqueueSnackbar('Employee created!', { variant: 'success' });
      navigate(locations.employeeList());
    }
    if (updateStatus === fetchStatuses.success) {
      enqueueSnackbar('Employee updated!', { variant: 'success' });
      dispatch(fetchEmployee(Number(id)));
    }
  }, [deleteStatus, createStatus, updateStatus]);

  useEffect(() => {
    if (Number(id)) {
      dispatch(fetchEmployee(Number(id)));
    }
  }, [id]);

  const onResetForm = () => {
    if (editEmployee) {
      resetForm({
        values: {
          [employeeFormFields.name]: editEmployee.name,
          [employeeFormFields.email]: editEmployee.email,
          [employeeFormFields.phone]: editEmployee.phone,
          [employeeFormFields.status]: editEmployee.status,
          [employeeFormFields.role]: editEmployee?.role?.id,
        },
      });
    } else {
      resetForm();
    }
  };

  useEffect(() => {
    onResetForm();
  }, [editEmployee]);

  const onDeleteEmployee = () => {
    dispatch(deleteEmployee(Number(id)));
  };

  const disableChange = (e: ClipboardEvent) => {
    e.preventDefault();
  };

  const renderForm = () => (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <Paper sx={{ width: '100%', height: '100%', padding: 2 }}>
            <Grid
              sx={{ width: '100%', height: '100%' }}
              container
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              {isLoading ? (
                <Skeleton sx={{ padding: 1 }} variant="circular" width={124} height={124} />
              ) : (
                <Box
                  sx={{
                    border: '1px dashed rgba(145, 158, 171, 0.32)',
                    borderRadius: '50%',
                    padding: 1,
                  }}
                >
                  <Avatar sx={{ width: 124, height: 124 }}>
                    {values[employeeFormFields.name]
                      .split(' ')
                      .slice(0, 2)
                      .map((i) => i.charAt(0))
                      .join('')}
                  </Avatar>
                </Box>
              )}
              {isLoading ? (
                <Skeleton variant="rectangular" width={142} height={38} sx={{ mt: 2 }} />
              ) : (
                <FormControlLabel
                  sx={{ mt: 2 }}
                  label="Active user"
                  labelPlacement="start"
                  componentsProps={{
                    typography: { textTransform: 'capitalize' },
                  }}
                  name="status"
                  id="status"
                  control={
                    <Switch
                      name={employeeFormFields.status}
                      disabled={editEmployee?.id === authUser?.id}
                      checked={values[employeeFormFields.status] === EmployeeStatus.ACTIVE}
                      onChange={(e, value) =>
                        handleChange({
                          target: {
                            name: employeeFormFields.status,
                            type: 'status',
                            value: value ? EmployeeStatus.ACTIVE : EmployeeStatus.INACTIVE,
                          },
                        })
                      }
                    />
                  }
                />
              )}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={9}>
          <Paper sx={{ width: '100%', height: '100%', padding: 2 }}>
            <Grid container spacing={2} flexDirection="column" paddingX={2} paddingBottom={3}>
              <Grid item>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    name={employeeFormFields.name}
                    label="Name"
                    type="name"
                    value={values[employeeFormFields.name]}
                    onChange={handleChange}
                    error={touched.name && !!errors.name}
                    helperText={touched.name && errors.name}
                    autoComplete="name"
                  />
                )}
              </Grid>
              <Grid item>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <TextField
                    id="email"
                    fullWidth
                    name={employeeFormFields.email}
                    label="Email"
                    value={values[employeeFormFields.email]}
                    onChange={handleChange}
                    error={touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                    disabled={Boolean(Number(id))}
                    autoComplete="username"
                    margin="normal"
                    required
                    autoFocus
                  />
                )}
              </Grid>
              <Grid item>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <PhoneInput
                    placeholder="Enter phone number"
                    value={values[employeeFormFields.phone]}
                    name={employeeFormFields.phone}
                    id="phone"
                    label="Phone"
                    type="phone"
                    inputComponent={CustomTextField}
                    numberInputProps={{
                      margin: 'normal',
                      required: true,
                      fullWidth: true,
                      id: 'phone',
                      name: employeeFormFields.phone,
                      label: 'Phone',
                      type: 'phone',
                      error: touched.phone && !!errors.phone,
                      helperText: touched.phone && errors.phone,
                      autoComplete: 'phone',
                    }}
                    onChange={(value: string) =>
                      handleChange({
                        target: {
                          name: employeeFormFields.phone,
                          type: 'phone',
                          value,
                        },
                      })
                    }
                  />
                )}
              </Grid>
              <Grid item>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <FormControl fullWidth margin="normal" error={touched.role && !!errors.role}>
                    <InputLabel id="role-label">Role</InputLabel>
                    <Select
                      labelId="role-label"
                      name={employeeFormFields.role}
                      value={values[employeeFormFields.role]}
                      label="Role"
                      onChange={handleChange}
                      defaultValue={0}
                    >
                      {(roles?.items || []).map((role) => (
                        <MenuItem key={role.id} value={role.id}>
                          {role.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.role && <FormHelperText>{errors.role}</FormHelperText>}
                  </FormControl>
                )}
              </Grid>
              <Grid item>
                {!Number(id) &&
                  (isLoading ? (
                    <Skeleton variant="rectangular" width="100%" height={80} />
                  ) : (
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="password"
                      name={employeeFormFields.password}
                      label="Password"
                      type="password"
                      value={values[employeeFormFields.password]}
                      onChange={handleChange}
                      onCut={disableChange}
                      onCopy={disableChange}
                      onPaste={disableChange}
                      error={touched.password && !!errors.password}
                      helperText={touched.password && errors.password}
                      autoComplete="current-password"
                    />
                  ))}
              </Grid>
              <Grid item>
                {!Number(id) &&
                  (isLoading ? (
                    <Skeleton variant="rectangular" width="100%" height={80} />
                  ) : (
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="password-confirmation"
                      name={employeeFormFields.passwordConfirmation}
                      label="Password Confirmation"
                      type="password"
                      value={values[employeeFormFields.passwordConfirmation]}
                      onChange={handleChange}
                      onCut={disableChange}
                      onCopy={disableChange}
                      onPaste={disableChange}
                      error={touched.passwordConfirmation && !!errors.passwordConfirmation}
                      helperText={touched.passwordConfirmation && errors.passwordConfirmation}
                      autoComplete="current-password-confirmation"
                    />
                  ))}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Grid>
        <Box m={1} p={1} display="flex" justifyContent="right" alignItems="flex-end">
          {editEmployee ? (
            <LoadingButton
              type="button"
              color="error"
              variant="contained"
              sx={{ mt: 3, mb: 2, mr: 2 }}
              endIcon={<DeleteIcon />}
              loading={isDeleteLoading}
              loadingPosition="end"
              onClick={onDeleteEmployee}
            >
              Delete
            </LoadingButton>
          ) : (
            <div />
          )}
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            sx={{ mt: 3, mb: 2, mr: 2 }}
            endIcon={<SaveIcon />}
            loading={isSaveLoading}
            loadingPosition="end"
          >
            {editEmployee ? 'Save' : 'Create'}
          </LoadingButton>
          <Button
            sx={{ mt: 3, mb: 2 }}
            variant="contained"
            color="warning"
            startIcon={<RestartAltIcon />}
            onClick={onResetForm}
          >
            Discard
          </Button>
        </Box>
      </Grid>
    </form>
  );

  return (
    <Container component="main" maxWidth="xl">
      {error && <Alert severity="error">{parseApiError(error)?.data?.message}</Alert>}
      <Box mt={2}>{renderForm()}</Box>
    </Container>
  );
}
