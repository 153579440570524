import { PaginatedRequest } from '../types/api';
import { FetchUserFeeCalculationResponse, FetchUserFeeCalculationsResponse } from '../types/userFeeCalculation';
import { toQueryParams } from '../utils/request';
import { ApiService } from './common/ApiService';

class UserFeeCalculationService extends ApiService {
  pathname = 'admin/user-fee-calculation';

  fetchUserFeeCalculations = async (data: PaginatedRequest) =>
    this.get<FetchUserFeeCalculationsResponse>(`${this.pathname}?${toQueryParams(data)}`);

  fetchUserFeeCalculation = async (id: number) => this.get<FetchUserFeeCalculationResponse>(`${this.pathname}/${id}`);
}

export const userFeeCalculationService = new UserFeeCalculationService();
