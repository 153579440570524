import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import WarningIcon from '@mui/icons-material/Warning';
import {
  GridActionsCellItem,
  GridActionsCellItemProps,
  GridRenderCellParams,
  GridColDef,
  GridRowParams,
} from '@mui/x-data-grid-premium';
import { GridActionsCellItemLink } from '../../components/FakeLink';
import { ProtectedContent } from '../../components/ProtectedContent';
import { Table } from '../../components/Table';
import { TableRef } from '../../components/Table/Table';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { usePermissions, useSearchParamsState } from '../../hooks';
import { useAppDispatch } from '../../redux/hooks';
import {
  cleanPortfolios,
  fetchPortfolios,
  updatePortfolioStatus,
} from '../../redux/modules/portfolio/portfolio.actions';
import { portfolioSelector } from '../../redux/modules/portfolio/portfolio.selectors';
import { locations } from '../../routes/locations';
import { Portfolio, PortfolioStatus } from '../../types/portfolio';
import { parseJSON } from '../../utils/json';
import * as permissions from '../../utils/permissions';

const portfolioStatusToInfo: {
  [prop in PortfolioStatus]: {
    color: 'error' | 'info' | 'success' | 'warning';
    icon: React.ElementType;
    menuAction: string;
  };
} = {
  [PortfolioStatus.ACTIVE]: {
    color: 'success',
    menuAction: 'Activate',
    icon: CheckCircleIcon,
  },
  [PortfolioStatus.INACTIVE]: {
    color: 'warning',
    menuAction: 'Deactivate',
    icon: WarningIcon,
  },
};

export function PortfolioList() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const tableRef = useRef<TableRef>(null);
  const [selectedRow, setSelectedRow] = useState<Portfolio>();
  const [actionMenu, setActionMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const canRead = usePermissions({ items: [permissions.Portfolio.read] });
  const [columns, setColumns] = useState<GridColDef[]>([
    {
      field: 'id',
      headerName: 'Id',
      width: 80,
    },
    {
      field: 'nameEn',
      headerName: 'Name En',
      type: 'string',
      width: 100,
    },
    {
      field: 'nameAr',
      headerName: 'Name Ar',
      type: 'string',
      width: 100,
    },
    {
      field: 'descriptionEn',
      headerName: 'Description En',
      type: 'string',
      width: 130,
    },
    {
      field: 'descriptionAr',
      headerName: 'Description Ar',
      type: 'string',
      width: 130,
    },
    {
      field: 'objectiveEn',
      headerName: 'Objective En',
      type: 'string',
      width: 130,
    },
    {
      field: 'objectiveAr',
      headerName: 'Objective Ar',
      type: 'string',
      width: 130,
    },
    {
      field: 'currency.name',
      headerName: 'currency',
      type: 'string',
      valueGetter: (params) => params.row?.currency?.name,
    },
    {
      field: 'manager.name',
      headerName: 'manager',
      type: 'string',
      valueGetter: (params) => params.row?.manager?.nameEn,
    },
    {
      field: 'shariaaComplaint',
      headerName: 'Shariaa',
      type: 'boolean',
      width: 80,
    },
    {
      field: 'status.name',
      headerName: 'Status',
      type: 'singleSelect',
      valueOptions: Object.values(PortfolioStatus),
      minWidth: 120,
      valueGetter: (params) => params.row?.status?.name,
      renderCell: (params: GridRenderCellParams) => {
        const info = portfolioStatusToInfo[params.row?.status?.name as PortfolioStatus] || {};
        const Icon = info?.icon;

        if (!Icon) {
          return params.row?.status?.name;
        }

        return <Chip variant="outlined" color={info?.color} icon={<Icon />} label={params.row?.status?.name} />;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      type: 'date',
      width: 100,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      type: 'date',
      width: 100,
    },
  ]);
  const canDoActions = usePermissions({ items: [permissions.Portfolio.statusUpdate] });
  const { totalPortfolios, portfolios, fetchStatus, updateStatusStatus } = useSelector(portfolioSelector);
  const updateStatusLoading = updateStatusStatus === fetchStatuses.pending;
  const loading = fetchStatus === fetchStatuses.pending;
  const availableStatuses = Object.values(PortfolioStatus).filter(
    (s) => s !== selectedRow?.status?.name,
  ) as PortfolioStatus[];
  const [initialFilterModel, setInitialFilterModel] = useSearchParamsState('filter', '');
  const [initialPage, setInitialPage] = useSearchParamsState('page', '0');
  const [initialPageSize, setInitialPageSize] = useSearchParamsState('pageSize', '100');

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, data: Portfolio) => {
    setSelectedRow(data);
    setActionMenu(
      actionMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
        : null,
    );
  };

  const handleCloseMenu = () => {
    setActionMenu(null);
  };

  const updateStatus = async (id: number, status: PortfolioStatus) => {
    dispatch(updatePortfolioStatus({ id, status }));
    handleCloseMenu();
  };

  const onAddNew = (): void => {
    navigate(locations.portfolio(0));
  };

  useEffect(
    () => () => {
      dispatch(cleanPortfolios());
    },
    [],
  );

  useEffect(() => {
    if (updateStatusStatus === fetchStatuses.success) {
      tableRef?.current?.reload();
      enqueueSnackbar('IndexFund status updated!', { variant: 'success' });
    }
    if (updateStatusStatus === fetchStatuses.rejected) {
      enqueueSnackbar('IndexFund update status error!', { variant: 'error' });
    }
  }, [updateStatusStatus]);

  useEffect(() => {
    const actionColumn = {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      hideable: false,
      filterable: false,
      sortable: false,
      getActions: (params: GridRowParams) => {
        const actions: React.ReactElement<GridActionsCellItemProps>[] = [];

        if (canRead) {
          actions.push(
            <GridActionsCellItemLink
              key={`portfolio-list-read-${params.row.id}`}
              icon={<OpenInNewIcon />}
              label="Link"
              href={locations.portfolio(params.row.id)}
            />,
          );
        }

        if (canDoActions) {
          actions.push(
            <GridActionsCellItem
              key={`employee-list-actions-${params.row.id}`}
              icon={<MoreVertIcon />}
              label="Actions"
              onClick={(e) => handleMenuOpen(e, params.row)}
            />,
          );
        }

        return actions;
      },
    };

    if (canRead || canDoActions) {
      setColumns([actionColumn, ...columns]);
    }
  }, [canRead, canDoActions]);

  return (
    <Container component="main" maxWidth="xl">
      <Box sx={{ pb: 5 }}>
        <Typography variant="h4">
          Portfolios &nbsp;
          <ProtectedContent items={[permissions.Portfolio.create]}>
            <Fab color="primary" size="small" aria-label="Create IndexFund" onClick={onAddNew}>
              <AddIcon />
            </Fab>
          </ProtectedContent>
        </Typography>
      </Box>
      <Table
        ref={tableRef}
        fetchItems={fetchPortfolios}
        rows={portfolios}
        columns={columns}
        loading={loading}
        rowCount={totalPortfolios}
        initialFilterModel={parseJSON(initialFilterModel)}
        initialPage={+initialPage}
        initialPageSize={+initialPageSize}
        onFilterModelChange={(filterModel) => setInitialFilterModel(JSON.stringify(filterModel))}
        onPageChange={(page) => setInitialPage(`${page}`)}
        onPageSizeChange={(pageSize) => setInitialPageSize(`${pageSize}`)}
      />
      <Menu
        open={actionMenu !== null}
        onClose={handleCloseMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          actionMenu !== null
            ? {
                top: actionMenu.mouseY,
                left: actionMenu.mouseX,
              }
            : undefined
        }
      >
        {availableStatuses.map((status) => {
          const info = portfolioStatusToInfo[status] || {};

          return (
            <MenuItem
              key={`portfolio-list-menu-action-${status}`}
              onClick={() => selectedRow && updateStatus(selectedRow.id, status)}
              disabled={updateStatusLoading}
            >
              {info.menuAction}
            </MenuItem>
          );
        })}
      </Menu>
    </Container>
  );
}
