import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import DialogContent from '@mui/material/DialogContent';
import { LoadingButton } from '@mui/lab';
import EmailIcon from '@mui/icons-material/Email';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { useSnackbar } from 'notistack';
import TextField from '@mui/material/TextField';
import { UserFeeDeductionRequest, UserFeeDeductionStatus } from '../../types/payment';
import { userFeeDeductionRequestRejectFormFields } from '../../forms/fields/formFields';
import { userFeeDeductionRequestRejectionFormSchema } from '../../forms/validationSchema/formSchema';
import { useUpdateUserFeeDeductionStatusMutation } from '../../redux/features/userFeeDeduction/userFeeDeduction.apiSlice';

interface UserFeeDeductionRequestCancelDialogProps {
  userFeeDeductionRequest: UserFeeDeductionRequest | undefined;
  onClose: () => void;
}

export function UserFeeDeductionRequestCancelDialog({
  userFeeDeductionRequest,
  onClose,
}: UserFeeDeductionRequestCancelDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [updateUserFeeDeductionStatus, { isLoading }] = useUpdateUserFeeDeductionStatusMutation();
  const { handleSubmit, values, handleChange, touched, errors, submitForm, setValues, resetForm } = useFormik({
    initialValues: {
      [userFeeDeductionRequestRejectFormFields.reviewComment]: '',
    },
    validationSchema: userFeeDeductionRequestRejectionFormSchema,
    onSubmit: async ({ reviewComment }) => {
      try {
        if (userFeeDeductionRequest?.id) {
          await updateUserFeeDeductionStatus({
            id: userFeeDeductionRequest.id,
            reviewComment,
            status: UserFeeDeductionStatus.REJECTED,
          }).unwrap();
          enqueueSnackbar('User Fee Deduction rejected.', { variant: 'success' });
        }
      } catch (err) {
        enqueueSnackbar('User Fee Deduction rejection error!', {
          variant: 'error',
        });
      }
      onClose();
    },
  });

  useEffect(() => {
    resetForm();
  }, [userFeeDeductionRequest]);

  return (
    <Dialog open={!!userFeeDeductionRequest}>
      <DialogTitle>Rejection reason</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            margin="normal"
            required
            fullWidth
            type="text"
            id={userFeeDeductionRequestRejectFormFields.reviewComment}
            name={userFeeDeductionRequestRejectFormFields.reviewComment}
            label="Comment"
            value={values[userFeeDeductionRequestRejectFormFields.reviewComment]}
            onChange={handleChange}
            error={touched.reviewComment && !!errors.reviewComment}
            helperText={touched.reviewComment && errors.reviewComment}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          type="button"
          color="primary"
          variant="contained"
          endIcon={<EmailIcon />}
          loading={isLoading}
          loadingPosition="end"
          onClick={submitForm}
        >
          Send
        </LoadingButton>
        <Button color="secondary" variant="contained" onClick={() => onClose()}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
