import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import { useSnackbar } from 'notistack';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useAppDispatch } from '../../redux/hooks';
import { fetchFeeConfigs } from '../../redux/modules/feeConfig/feeConfig.actions';
import { feeConfigSelector, feeConfigLoadingSelector } from '../../redux/modules/feeConfig/feeConfig.selectors';
import { FeeConfigsInfo } from './components/config/FeeConfigsInfo';
import { AddFeeConfigDialog } from './components/config/AddFeeConfigDialog';

export function FeeConfigTab() {
  const dispatch = useAppDispatch();
  const { error } = useSelector(feeConfigSelector);

  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const feeConfigs = useSelector(feeConfigSelector);
  const isLoading = useSelector(feeConfigLoadingSelector);

  useEffect(() => {
    dispatch(fetchFeeConfigs());
  }, []);

  const onAddNew = (): void => {
    setOpen(true);
  };

  const handleClose = (isReload: boolean) => {
    if (isReload) {
      dispatch(fetchFeeConfigs());
    }
    setOpen(false);
  };

  return (
    <>
      {error && <Alert severity="error">{error.message}</Alert>}

      {/* Max Number of Files to be uploaded is 5 */}
      {feeConfigs && <AddFeeConfigDialog open={open} onClose={handleClose} initialValue={null} />}

      <Grid item container flexDirection="column" component={Paper} sx={{ p: '24px', mt: 0 }} spacing={2}>
        <Typography variant="h6">
          Fee Configs &nbsp;
          <Fab color="primary" size="small" aria-label="Create Action" onClick={onAddNew}>
            <AddIcon />
          </Fab>
        </Typography>
        <FeeConfigsInfo />
      </Grid>
    </>
  );
}
