import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ColorlibConnector, PortfolioOrderStepIcon } from '../../components/Stepper';
import { useAppDispatch } from '../../redux/hooks';
import { fetchOrderExecutionByOrderId } from '../../redux/modules/orderExecution/orderExecution.actions';
import { orderExecutionByOrderIdSelector } from '../../redux/modules/orderExecution/orderExecution.selectors';
import {
  cleanViewDetailsByOrderId,
  fetchConsolidateRequestsByOrderId,
  fetchFundOrdersByOrderId,
  fetchPortfolioOrdersByOrderId,
  fetchSatelliteRequestsByOrderId,
  fetchUserPortfolioRequestsByOrderId,
} from '../../redux/modules/portfolioOrder/portfolioOrder.actions';
import { viewDetailsByOrderIdSelector } from '../../redux/modules/portfolioOrder/portfolioOrder.selectors';
import { ReducerState } from '../../redux/store.types';
import { locations } from '../../routes/locations';
import { FundOrder, PortfolioOrderExecutionStatus, PortfolioOrderStatus } from '../../types/portfolioOrder';
import { PortfolioOrderActions } from './PortfolioOrderActions';
import { PortfolioOrderTabList } from './PortfolioOrderTabList';

const steps = ['File execution', 'Review and Validation', 'Reconciliation', 'Approved'];

const mapPortfolioOrderStatusToStep: Record<string, number> = {
  [PortfolioOrderStatus.IN_PROGRESS]: 0,
  [PortfolioOrderStatus.EXECUTED]: 0,
};

const mapOrderExecutionStatusToStep: Record<string, number> = {
  [PortfolioOrderExecutionStatus.EXECUTION]: 0,
  [PortfolioOrderExecutionStatus.IN_REVIEW]: 1,
  [PortfolioOrderExecutionStatus.RECONCILIATION]: 2,
  [PortfolioOrderExecutionStatus.APPROVED]: 3,
};

export function PortfolioOrder() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [fundOrdersKey, setFundOrdersKey] = useState<string>(`${Date.now()}`);
  const [executionTemplateData, setExecutionTemplateData] = useState<IAnyObject[]>([]);
  const [selectedFundOrders, setSelectedFundOrders] = useState<FundOrder[]>([]);
  const orderExecution = useSelector((state: ReducerState) => orderExecutionByOrderIdSelector(state, id || ''));
  const { portfolioOrders, fundOrders, satelliteAccountRequests } = useSelector(viewDetailsByOrderIdSelector);
  const portfolioOrderStatus = portfolioOrders.length && portfolioOrders?.[0].status?.name;
  const showAlpacaTab = fundOrders.some((fo) => fo.indexFund.manager?.nameEn?.toLowerCase()?.includes('alpaca'));
  const showJadwaTab = fundOrders.some((fo) => fo.indexFund.manager?.nameEn?.toLowerCase()?.includes('jadwa'));

  const fetchPortfolioOrders = useCallback(() => {
    if (id) {
      dispatch(fetchPortfolioOrdersByOrderId({ limit: 10, offset: 0, orderId: id }));
    }
  }, []);

  const fetchOrderExecution = useCallback(() => {
    if (id) {
      dispatch(fetchOrderExecutionByOrderId(id));
    }
  }, []);

  const fetchConsolidateRequests = useCallback(() => {
    if (id) {
      dispatch(fetchConsolidateRequestsByOrderId({ limit: 10, offset: 0, orderId: id }));
    }
  }, []);

  const fetchUserPortfolioRequests = useCallback(() => {
    if (id) {
      dispatch(fetchUserPortfolioRequestsByOrderId({ limit: 10, offset: 0, orderId: id }));
    }
  }, []);

  const fetchSatelliteRequests = useCallback(() => {
    if (id) {
      dispatch(fetchSatelliteRequestsByOrderId({ limit: 10, offset: 0, orderId: id }));
    }
  }, []);

  useEffect(() => {
    if (!id) {
      return navigate(locations.portfolioOrderList(), { replace: true });
    }

    dispatch(fetchFundOrdersByOrderId({ orderId: id, limit: 25 }));
    dispatch(fetchSatelliteRequestsByOrderId({ orderId: id }));
  }, [id]);

  useEffect(() => {
    fetchPortfolioOrders();
    fetchOrderExecution();
    fetchSatelliteRequests();
    fetchConsolidateRequests();
    fetchUserPortfolioRequests();

    return () => {
      dispatch(cleanViewDetailsByOrderId);
    };
  }, []);

  useEffect(() => {
    const data = fundOrders.map((fo) => {
      const accountRequest = satelliteAccountRequests.find((sar) => sar?.fundOrder?.id === fo.id) || {
        amount: 0,
        numOfUnits: 0,
      };

      return {
        fundOrderId: fo.id,
        numOfUnits: fo.sharesCount + accountRequest.numOfUnits,
        actualAmount: fo.estimatedAmount + accountRequest.amount,
        status: 'done',
      };
    });

    setExecutionTemplateData(data);
  }, [fundOrders, satelliteAccountRequests]);

  const onSelectFundOrders = (ids: FundOrder[]) => setSelectedFundOrders(ids);

  const getPortfolioOrderStep = () => {
    if (orderExecution) {
      return mapOrderExecutionStatusToStep[orderExecution?.status?.name];
    }

    return mapPortfolioOrderStatusToStep[portfolioOrderStatus];
  };

  return (
    <Container component="main" maxWidth="xl">
      {getPortfolioOrderStep() !== undefined && (
        <Stack sx={{ width: '100%', paddingBottom: 2 }} spacing={4}>
          <Stepper alternativeLabel activeStep={getPortfolioOrderStep()} connector={<ColorlibConnector />}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={PortfolioOrderStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>
      )}

      {id && (
        <PortfolioOrderActions
          orderId={id}
          setFundOrdersKey={setFundOrdersKey}
          selectedFundOrders={selectedFundOrders}
          executionTemplateData={executionTemplateData}
          fetchPortfolioOrders={fetchPortfolioOrders}
          fetchOrderExecution={fetchOrderExecution}
          fetchSatelliteRequests={fetchSatelliteRequests}
          fetchConsolidateRequests={fetchConsolidateRequests}
        />
      )}

      <PortfolioOrderTabList
        orderId={id}
        fundOrdersKey={fundOrdersKey}
        onSelectFundOrders={onSelectFundOrders}
        showAlpacaTab={showAlpacaTab}
        showJadwaTab={showJadwaTab}
      />
    </Container>
  );
}
