import { PaginatedRequest } from '../types/api';
import {
  CreateFundStrategyRequest,
  CreateFundStrategyResponse,
  FetchFundStrategyResponse,
  FetchFundStrategiesAutocompleteResponse,
  FetchFundStrategiesResponse,
  UpdateFundStrategyRequest,
  UpdateFundStrategyResponse,
} from '../types/fundStrategy';
import { toQueryParams } from '../utils/request';
import { ApiService } from './common/ApiService';

class FundStrategyService extends ApiService {
  pathname = 'admin';

  fetchFundStrategies = async (data: PaginatedRequest) =>
    this.get<FetchFundStrategiesResponse>(`${this.pathname}/fund-strategy?${toQueryParams(data)}`);

  fetchFundStrategiesAutocomplete = async (term: string) =>
    this.get<FetchFundStrategiesAutocompleteResponse>(`${this.pathname}/fund-strategy/autocomplete?term=${term}`);

  fetchFundStrategy = async (id: number) => this.get<FetchFundStrategyResponse>(`${this.pathname}/fund-strategy/${id}`);

  deleteFundStrategy = async (id: number) =>
    this.delete<FetchFundStrategyResponse>(`${this.pathname}/fund-strategy/${id}`);

  createFundStrategy = async (fundStrategy: CreateFundStrategyRequest) =>
    this.post<CreateFundStrategyResponse, CreateFundStrategyRequest>(`${this.pathname}/fund-strategy`, fundStrategy);

  updateFundStrategy = async ({ id, ...fundStrategy }: UpdateFundStrategyRequest) =>
    this.put<UpdateFundStrategyResponse, Omit<UpdateFundStrategyRequest, 'id'>>(
      `${this.pathname}/fund-strategy/${id}`,
      fundStrategy,
    );
}

export const fundStrategyService = new FundStrategyService();
