import { PaginatedRequest } from '../types/api';
import {
  CreateFeeConfigRequest,
  CreateFeeConfigResponse,
  FetchFeeConfigHistoryResponse,
  FetchFeeConfigResponse,
  FetchFeeConfigsResponse,
  UpdateFeeConfigRequest,
  UpdateFeeConfigResponse,
} from '../types/feeConfig';
import { toQueryParams } from '../utils/request';
import { ApiService } from './common/ApiService';

class FeeConfigService extends ApiService {
  pathname = 'admin/fee-config';

  fetchFeeConfigs = async () => this.get<FetchFeeConfigsResponse>(`${this.pathname}`);

  fetchFeeConfigHistory = async (data: PaginatedRequest) =>
    this.get<FetchFeeConfigHistoryResponse>(`${this.pathname}/history?${toQueryParams(data)}`);

  fetchFeeConfig = async (id: number) => this.get<FetchFeeConfigResponse>(`${this.pathname}/${id}`);

  deleteFeeConfig = async (id: number) => this.delete<FetchFeeConfigResponse>(`${this.pathname}/${id}`);

  createFeeConfig = async (action: CreateFeeConfigRequest) =>
    this.post<CreateFeeConfigResponse, CreateFeeConfigRequest>(`${this.pathname}`, action);

  updateFeeConfig = async ({ id, ...action }: UpdateFeeConfigRequest) =>
    this.put<UpdateFeeConfigResponse, Omit<UpdateFeeConfigRequest, 'id'>>(`${this.pathname}/${id}`, action);
}

export const feeConfigService = new FeeConfigService();
