import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { GridColDef } from '@mui/x-data-grid-premium';
import { Table } from '../../../../components/Table';
import { fetchStatuses } from '../../../../constants/fetchStatuses';
import { fetchUserSubscriptions } from '../../../../redux/modules/user/user.actions';
import { userSubscriptionsSelector } from '../../../../redux/modules/user/user.selectors';
import { round2Format } from '../../../../utils/number';

interface UserRecurringSubscriptionsTableProps {
  userId: number;
}

export function UserRecurringSubscriptionsTable({ userId }: UserRecurringSubscriptionsTableProps) {
  const columns = useRef<GridColDef[]>([
    { field: 'id', headerName: 'Id' },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 150,
      valueFormatter: (params) => round2Format(params.value),
    },
    {
      field: 'frequencyUnit',
      headerName: 'Frequency Unit',
      width: 150,
    },
    {
      field: 'frequencyCount',
      headerName: 'Frequency Count',
      width: 150,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      width: 150,
    },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      width: 150,
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      width: 150,
    },
    {
      field: 'endDateType',
      headerName: 'End Date Type',
      width: 150,
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      sortable: false,
      type: 'date',
      width: 100,
    },
  ]);
  const { totalUserSubscriptionItems, userSubscriptionItems, fetchUserSubscriptionsStatus } =
    useSelector(userSubscriptionsSelector);

  return (
    <Table
      fetchItems={fetchUserSubscriptions}
      fetchItemsCustomParams={{ userId }}
      rows={userSubscriptionItems}
      columns={columns.current}
      loading={fetchUserSubscriptionsStatus === fetchStatuses.pending}
      rowCount={totalUserSubscriptionItems}
      disableColumnFilter
      disableColumnMenu
      density="compact"
      initialColumnVisibilityModel={{ id: false }}
      initialSortModel={[{ field: 'id', sort: 'desc' }]}
      wrapperHeight={300}
      toolbarExportProps={{
        csvOptions: { fileName: `user-${userId}-subscriptions-${Date.now()}` },
        excelOptions: { fileName: `user-${userId}-subscriptions-${Date.now()}` },
      }}
    />
  );
}
