import { PaginatedRequest } from '../types/api';
import {
  CreateIndexFundRequest,
  CreateIndexFundResponse,
  FetchIndexFundsResponse,
  FetchIndexFundResponse,
  UpdateIndexFundRequest,
  UpdateIndexFundResponse,
  FetchFundRiskLevelOptionsResponse,
  FetchFundCategoryOptionsResponse,
  FetchFundStatusOptionsResponse,
  FetchFundTypeOptionsResponse,
  ImportFundNavDateFile,
  FetchFundNavDataResponse,
  ImportFundChartDateFile,
  BulkUpdateIndexFundNavs,
  FetchFundSourceOptionsResponse,
  FetchFundNabHistoryResponse,
} from '../types/indexFund';
import { toQueryParams } from '../utils/request';
import { ApiService } from './common/ApiService';

class IndexFundService extends ApiService {
  pathname = 'admin';

  fetchIndexFunds = async (data: PaginatedRequest) =>
    this.get<FetchIndexFundsResponse>(`${this.pathname}/index-fund?${toQueryParams(data)}`);

  fetchFundNavHistory = async (data: PaginatedRequest) =>
    this.get<FetchFundNabHistoryResponse>(`${this.pathname}/fund-nav-history?${toQueryParams(data)}`);

  fetchIndexFund = async (id: number) => this.get<FetchIndexFundResponse>(`${this.pathname}/index-fund/${id}`);

  deleteIndexFund = async (id: number) => this.delete<FetchIndexFundResponse>(`${this.pathname}/index-fund/${id}`);

  createIndexFund = async (asset: CreateIndexFundRequest) =>
    this.post<CreateIndexFundResponse, CreateIndexFundRequest>(`${this.pathname}/index-fund`, asset);

  updateIndexFund = async ({ id, ...asset }: UpdateIndexFundRequest) =>
    this.put<UpdateIndexFundResponse, Omit<UpdateIndexFundRequest, 'id'>>(`${this.pathname}/index-fund/${id}`, asset);

  bulkUpdateIndexFundNavs = async (items: BulkUpdateIndexFundNavs[]) =>
    this.put<UpdateIndexFundResponse, { items: BulkUpdateIndexFundNavs[] }>(`${this.pathname}/index-fund/nav/bulk`, {
      items,
    });

  fetchFundRiskLevelOptions = async () =>
    this.get<FetchFundRiskLevelOptionsResponse>(`${this.pathname}/index-fund/fund-risk-level/options`);

  fetchFundCategoryOptions = async () =>
    this.get<FetchFundCategoryOptionsResponse>(`${this.pathname}/index-fund/fund-category/options`);

  fetchFundStatusOptions = async () =>
    this.get<FetchFundStatusOptionsResponse>(`${this.pathname}/index-fund/fund-status/options`);

  fetchFundTypeOptions = async () =>
    this.get<FetchFundTypeOptionsResponse>(`${this.pathname}/index-fund/fund-type/options`);

  fetchFundSourceOptions = async () =>
    this.get<FetchFundSourceOptionsResponse>(`${this.pathname}/index-fund/fund-source/options`);

  importNavDataFile = async (payload: ImportFundNavDateFile) => {
    const formData = new FormData();
    formData.append('file', payload.file);

    return this.post(`${this.pathname}/index-fund/${payload.indexFundId}/import-nav-data-file`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  };

  importFundChartDataFile = async (payload: ImportFundChartDateFile) => {
    const formData = new FormData();
    formData.append('file', payload.file);

    return this.post(
      `${this.pathname}/index-fund/${payload.indexFundId}/benchmark/${payload.benchmarkId}/import-chart-data-file`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );
  };

  getExampleNavDataFile = async () =>
    this.get(`${this.pathname}/index-fund/example-nav-data-file`, { responseType: 'blob' });

  getExampleFundChartDataFile = async () =>
    this.get(`${this.pathname}/index-fund/example-chart-data-file`, { responseType: 'blob' });

  exportNavData = async (id: number) =>
    this.get(`${this.pathname}/index-fund/${id}/export-nav-data`, { responseType: 'blob' });

  fetchNavDataByFund = async (id: number) =>
    this.get<FetchFundNavDataResponse>(`${this.pathname}/index-fund/${id}/nav-data`);
}

export const indexFundService = new IndexFundService();
