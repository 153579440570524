import { ApiResponse } from './api';
import { Asset } from './asset';
import { Benchmark } from './benchmark';
import { FundGeographicalFocus } from './fundGeographicalFocus';
import { FundManager } from './fundManager';
import { FundStrategy } from './fundStrategy';
import { FundValuationDay } from './fundValuationDay';
import { FundValuationFrequency } from './fundValuationFrequency';

export enum FundRiskLevel {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  LOW_TO_MEDIUM = 'LOW_TO_MEDIUM',
  MEDIUM_TO_HIGH = 'MEDIUM_TO_HIGH',
}

export interface FundRiskLevelOption {
  id: number;
  name: FundRiskLevel;
}

export enum FundCategory {
  MUTUAL_FUND = 'Mutual Fund',
  PRIVATE_FUND = 'Private Fund',
  ETF = 'ETF',
}

export interface FundCategoryOption {
  id: number;
  name: FundCategory;
}

export enum FundSource {
  YAHOO_FINANCE = 'Yahoo Finance',
  ALPACA_MARKET = 'Alpaca Market',
}

export interface FundSourceOption {
  id: number;
  name: FundSource;
}

export enum FundStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export interface FundStatusOption {
  id: number;
  name: FundStatus;
}

export enum FundType {
  OPEN_ENDED_FUND = 'Open-Ended-Fund',
  CLOSE_ENDED_FUND = 'Close-Ended-Fund',
}

export interface FundTypeOption {
  id: number;
  name: FundType;
}

export interface FundCurrencyOption {
  id: number;
  name: string;
}

export interface FundAttachment {
  id: number;
  documentLink: string;
}

export interface FundNavDataItem {
  id: number;
  nav: number;
  dateTime: Date | string;
}

export type IndexFund = {
  id: number;
  bloombergTicker: string;
  code: string;
  nameEn: string;
  nameAr: string;
  secondNameEn: string;
  secondNameAr: string;
  asset: Asset;
  source: FundSourceOption;
  strategy: FundStrategy;
  manager: FundManager;
  objectiveEn: string;
  objectiveAr: string;
  geographicalFocus: FundGeographicalFocus;
  shariaaComplaint: boolean;
  allowFractions: boolean;
  expenseRatio: number;
  valuationDay: FundValuationDay;
  valuationFrequency: FundValuationFrequency;
  type: FundTypeOption;
  category: FundCategoryOption;
  currency: FundCurrencyOption;
  status: FundStatusOption;
  netAssetValuePerUnit: number;
  originalNav: number;
  fxRate: number;
  assetUnderManagement: number;
  inceptionDate: Date;
  managementFee: number;
  minimumSubscription: number;
  minimumRedemption: number;
  subscriptionFee: number;
  redemptionFee: number;
  otherFee: number;
  benchmark: Benchmark;
  topHoldings: string;
  topHoldingsAr: string;
  numberHoldings: number;
  riskLevel: FundRiskLevelOption;
  y1mReturn: number;
  y3mReturn: number;
  y5mReturn: number;
  y1mStdev: number;
  y3mStdev: number;
  y5mStdev: number;
  y1mReturnRisk: number;
  y3mReturnRisk: number;
  y5mReturnRisk: number;
  attachments: FundAttachment[];
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type FundNavHistoryItem = {
  id: number;
  dateTime: string;
  nav: number;
  createdAt: Date | string;
  updatedAt: Date | string;
  indexFund?: IndexFund;
};

export type FetchIndexFundResponse = ApiResponse<IndexFund>;
export type FetchIndexFundsResponse = ApiResponse<{ items: IndexFund[]; totalItems: number }>;
export type FetchFundNabHistoryResponse = ApiResponse<{ items: FundNavHistoryItem[]; totalItems: number }>;

export type FetchFundRiskLevelOptionsResponse = ApiResponse<FundRiskLevelOption[]>;
export type FetchFundCategoryOptionsResponse = ApiResponse<FundCategoryOption[]>;
export type FetchFundStatusOptionsResponse = ApiResponse<FundStatusOption[]>;
export type FetchFundTypeOptionsResponse = ApiResponse<FundTypeOption[]>;
export type FetchFundSourceOptionsResponse = ApiResponse<FundSourceOption[]>;
export type FetchFundNavDataResponse = ApiResponse<FundNavDataItem[]>;

export type CreateIndexFundRequest = Omit<
  IndexFund,
  | 'id'
  | 'asset'
  | 'benchmark'
  | 'strategy'
  | 'manager'
  | 'source'
  | 'geographicalFocus'
  | 'valuationDay'
  | 'valuationFrequency'
  | 'type'
  | 'category'
  | 'currency'
  | 'status'
  | 'riskLevel'
  | 'attachments'
  | 'createdAt'
  | 'updatedAt'
> & {
  id?: number;
  asset: number;
  source: number | null;
  strategy: number;
  manager: number;
  geographicalFocus: number;
  valuationDay: number;
  valuationFrequency: number;
  type: number;
  category: number;
  currency: number;
  status: number;
  riskLevel: number;
  benchmark: number;
  attachments: Omit<FundAttachment, 'id'>[];
};
export type CreateIndexFundResponse = ApiResponse<IndexFund>;
export type ImportFundNavDateFile = { indexFundId: number; file: File };
export type ImportFundChartDateFile = { benchmarkId: number; indexFundId: number; file: File };

export type UpdateIndexFundRequest = Omit<
  IndexFund,
  | 'asset'
  | 'source'
  | 'benchmark'
  | 'strategy'
  | 'manager'
  | 'geographicalFocus'
  | 'valuationDay'
  | 'valuationFrequency'
  | 'type'
  | 'category'
  | 'currency'
  | 'status'
  | 'riskLevel'
  | 'attachments'
  | 'createdAt'
  | 'updatedAt'
> & {
  asset: number;
  source: number | null;
  strategy: number;
  manager: number;
  geographicalFocus: number;
  valuationDay: number;
  valuationFrequency: number;
  type: number;
  category: number;
  currency: number;
  status: number;
  riskLevel: number;
  benchmark: number;
  attachments: Omit<FundAttachment, 'id'>[];
};
export type UpdateIndexFundAttachment = Optional<FundAttachment, 'id'>;
export type BulkUpdateIndexFundNavs = { id: number; nav: number; originalNav: number };
export type UpdateIndexFundResponse = ApiResponse<IndexFund>;
