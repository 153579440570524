import { apiSlice } from '../../../api/common/apiSlice';
import { PaginatedRequest } from '../../../types/api';
import { toQueryParams } from '../../../utils/request';
import { ApiTagType } from '../../../constants/apiTagType';
import {
  FetchUserFeeDeductionRequestsResponse,
  UpdateUserFeeDeductionRequestRequest,
  UpdateUserFeeDeductionRequestResponse,
} from '../../../types/payment';

export const userFeeDeductionApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchUserFeeDeductions: builder.query<FetchUserFeeDeductionRequestsResponse, PaginatedRequest>({
      query: (data) => ({
        url: `/admin/payment/user-fee-deduction?${toQueryParams(data)}`,
        method: 'GET',
      }),
      providesTags: (res) => {
        if (res) {
          return [
            ...(res.items || []).map(({ id }) => ({ type: ApiTagType.UserFeeDeductionRequest as const, id })),
            { type: ApiTagType.UserFeeDeductionRequest, id: 'LIST' },
          ];
        }
        return [{ type: ApiTagType.UserFeeDeductionRequest, id: 'LIST' }];
      },
    }),
    updateUserFeeDeductionStatus: builder.mutation<
      UpdateUserFeeDeductionRequestResponse,
      UpdateUserFeeDeductionRequestRequest
    >({
      query: ({ id, ...data }) => ({
        url: `/admin/payment/user-fee-deduction/${id}/status`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (res, error, req) => [
        { type: ApiTagType.UserFeeDeductionRequest, id: req.id },
        { type: ApiTagType.UserFeeDeductionRequest, id: 'LIST' },
      ],
    }),
  }),
});

export const { useFetchUserFeeDeductionsQuery, useUpdateUserFeeDeductionStatusMutation } = userFeeDeductionApiSlice;
