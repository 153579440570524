import React, { useRef, useState } from 'react';
import { GridActionsCellItemProps, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-premium';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import moment from 'moment';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import Alert from '@mui/material/Alert';
import DialogContentText from '@mui/material/DialogContentText';
import {
  useFetchUserFeeDeductionsQuery,
  useUpdateUserFeeDeductionStatusMutation,
} from '../../redux/features/userFeeDeduction/userFeeDeduction.apiSlice';
import { RtkQueryTable, RtkQueryTableModels } from '../../components/Table/RtkQueryTable';
import { converTableModelsToApiArgs, parseApiError } from '../../helpers/api';
import { TableRef } from '../../components/Table/Table';
import { UserFeeDeductionRequest, UserFeeDeductionStatus } from '../../types/payment';
import { userFeeDeductionRequestStatusToInfo } from '../../constants/tables/userFeeDeductionRequest';
import { FakeButtonLink } from '../../components/FakeLink';
import { locations } from '../../routes/locations';
import { UserFeeDeductionRequestCancelDialog } from './UserFeeDeductionRequestCancelDialog';

export function UserFeeDeductionsTab() {
  const tableRef = useRef<TableRef>(null);
  const [tableModels, setTableModels] = useState<RtkQueryTableModels>({
    paginationModel: { page: 0, pageSize: 100 },
    sortModel: [{ field: 'id', sort: 'desc' }],
  });
  const [updateUserFeeDeductionStatus, { isLoading: approveLoading, error: approveError }] =
    useUpdateUserFeeDeductionStatusMutation();

  const [rejectedUserFeeDeduction, setRejectedUserFeeDeduction] = useState<UserFeeDeductionRequest | undefined>(
    undefined,
  );
  const { data, isFetching } = useFetchUserFeeDeductionsQuery(converTableModelsToApiArgs(tableModels));

  const onApprove = async (userFeeDeduction) => {
    await updateUserFeeDeductionStatus({
      id: userFeeDeduction.id,
      reviewComment: '',
      status: UserFeeDeductionStatus.APPROVED,
    }).unwrap();
  };
  const onRejectionDialogClose = () => {
    setRejectedUserFeeDeduction(undefined);
  };

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 70,
    },
    {
      field: 'userId',
      headerName: 'UserId',
      type: 'string',
      width: 100,
      valueGetter: (params) => params.row?.userId,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return;
        }

        return (
          <FakeButtonLink startIcon={<OpenInNewIcon />} href={locations.user(params.value)}>
            {params.value}
          </FakeButtonLink>
        );
      },
    },
    {
      field: 'totalFee',
      headerName: 'TotalFee',
      width: 110,
    },
    {
      field: 'status.name',
      headerName: 'Status',
      type: 'singleSelect',
      valueOptions: Object.values(UserFeeDeductionStatus),
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => params.row?.status?.name,
      renderCell: (params: GridRenderCellParams) => {
        const info = userFeeDeductionRequestStatusToInfo[params.row?.status?.name as UserFeeDeductionStatus] || {};
        const Icon = info?.icon;

        if (!Icon) {
          return params.row?.status?.name;
        }

        return <Chip variant="outlined" color={info?.color} icon={<Icon />} label={params.row?.status?.name} />;
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      hideable: false,
      filterable: false,
      sortable: false,
      width: 250,
      getActions: ({ row }: GridRowParams) => {
        const actions: React.ReactElement<GridActionsCellItemProps>[] = [];

        if (row.status.name === UserFeeDeductionStatus.PENDING) {
          actions.push(
            <Button
              id="ApproveButton"
              variant="outlined"
              component="label"
              aria-label="Approve"
              color="success"
              startIcon={<DoneAllIcon />}
              onClick={() => onApprove(row)}
            >
              Approve
            </Button>,
          );
          actions.push(
            <Button
              id="DiscardOrderExecutionsButton"
              variant="outlined"
              component="label"
              aria-label="Discard Execution"
              color="error"
              startIcon={<CancelScheduleSendIcon />}
              onClick={() => setRejectedUserFeeDeduction(row)}
            >
              Reject
            </Button>,
          );
        }
        return actions;
      },
    },
    {
      field: 'reviewComment',
      headerName: 'Review Comment',
      width: 300,
    },
    {
      field: 'reviewedBy',
      headerName: 'Reviewed by',
      type: 'string',
      width: 120,
      valueGetter: (params) => params.row?.reviewedBy,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return;
        }

        return (
          <FakeButtonLink startIcon={<OpenInNewIcon />} href={locations.employee(params.value)}>
            {params.value}
          </FakeButtonLink>
        );
      },
    },
    {
      field: 'reviewedAt',
      headerName: 'Reviewed',
      sortable: false,
      type: 'string',
      width: 100,
      valueGetter: (params) => params.row?.reviewedAt,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return moment(params.value).format('MM/DD/YYYY');
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      sortable: false,
      type: 'date',
      width: 100,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      sortable: false,
      type: 'date',
      width: 100,
    },
  ];

  const onModelsChange = (models: RtkQueryTableModels) => {
    setTableModels(models);
  };

  return (
    <>
      <DialogContentText>
        {approveError && <Alert severity="error">{parseApiError(approveError)?.data?.message}</Alert>}
      </DialogContentText>
      <RtkQueryTable
        ref={tableRef}
        models={tableModels}
        onModelsChange={onModelsChange}
        rows={data?.items || []}
        columns={columns}
        loading={isFetching || approveLoading}
        rowCount={data?.totalItems || 0}
        initialSortModel={[{ field: 'id', sort: 'desc' }]}
      />
      <UserFeeDeductionRequestCancelDialog
        userFeeDeductionRequest={rejectedUserFeeDeduction}
        onClose={onRejectionDialogClose}
      />
    </>
  );
}
