import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { FetchUserFeeCalculationResponse, FetchUserFeeCalculationsResponse } from '../../../types/userFeeCalculation';
import {
  cleanUserFeeCalculations,
  fetchUserFeeCalculation,
  fetchUserFeeCalculations,
} from './userFeeCalculation.actions';
import { UserFeeCalculationState } from './userFeeCalculation.types';

const initialState: UserFeeCalculationState = {
  UserFeeCalculations: [],
  totalUserFeeCalculations: 0,
  fetchStatus: fetchStatuses.idle,
  editUserFeeCalculation: null,

  error: null,
};

const userFeeCalculationsSlice = createSlice({
  name: 'userFeeCalculations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserFeeCalculations.pending, (state) => {
      state.error = null;
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchUserFeeCalculations.fulfilled,
      (state, action: PayloadAction<FetchUserFeeCalculationsResponse>) => {
        state.UserFeeCalculations = action.payload.data?.items;
        state.totalUserFeeCalculations = action.payload.data?.totalItems;
        state.fetchStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchUserFeeCalculations.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchUserFeeCalculation.pending, (state) => {
      state.error = null;
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchUserFeeCalculation.fulfilled,
      (state, action: PayloadAction<FetchUserFeeCalculationResponse>) => {
        state.editUserFeeCalculation = action.payload.data;
        state.fetchStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchUserFeeCalculation.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(cleanUserFeeCalculations.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const userFeeCalculationReducer = userFeeCalculationsSlice.reducer;
